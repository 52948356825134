import { Panel, PrimaryButton, ProgressIndicator, Stack, StackItem, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { GenericTrackerPage } from "./GenericTrackerPage";
import { IQuoteTrackerHttpClient, QuoteTrackerHttpClient } from "../services/QuoteTrackerHttpClient";
import { GetPrincipalId, PageLayoutContext } from './PageLayout';
import { useBoolean } from '@fluentui/react-hooks';
import { createScope, progressRing } from '@harmony/enablers/react';
import jwt_decode from "jwt-decode";

const scope = createScope({
    reactInstance: React
  });
  
const ProgressRing = scope.forReact(progressRing);


export enum AppRoles {
    Admin = "Admin",
    Copilot = "Copilot"
};

const TokenHasRole = (token: any, role: AppRoles) => {
    var decodedToken = jwt_decode(token);
    return (decodedToken as any)?.roles?.includes(role.toString());
};

export default () => {
    const { myTrackerList, trackedQuotesRefresh, triggerTrackedQuotesRefresh, myTrackerData, setCurrentErrorMessageWithTimeout, setCurrentWarningMessageWithTimeout, setCurrentSuccessMessageWithTimeout, accessToken, generateToken } = useContext(PageLayoutContext) as any;

    const [businessTrackerData, setBusinessTrackerData] = useState<any>({id: 'businessProposals'});
    const [businessTrackerList, setBusinessTrackerList] = useState<any>([]);

    const [isAddQuotePanelOpen, { setTrue: openAddQuotePanel, setFalse: dismissAddQuotePanel }] = useBoolean(false);
    const [addQuoteButtonDisabled, setAddQuoteButtonDisabled] = useState<boolean>(true);

    const [currentAddQuoteTextInput, setCurrentAddQuoteTextInput] = useState<string>('');
    const [currentlyFetchingData, setCurrentlyFetchingData] = useState<boolean>(false);
    const [bizTrackedQuotesRefresh, triggerBizTrackedQuotesRefresh] = useState(false);

    var quoteTimelineService = new QuoteTrackerHttpClient(setCurrentErrorMessageWithTimeout, setCurrentlyFetchingData, console.log, console.log);
    useEffect(() => {
        setAddQuoteButtonDisabled(currentlyFetchingData)
    }, [currentlyFetchingData]);

    const AddNewBusinessQuoteToTrack = (quoteTimelineService: IQuoteTrackerHttpClient, payload: any, trackerState: any, trackerList: any, accessToken: any, dismissPanel: any, setCurrentSuccessMessageWithTimeout: any, setCurrentErrorMessageWithTimeout: any, setCurrentWarningMessage: any) => {
            var someQuotesAlreadyThere = false;
            if (payload?.length >= 12)
            {
                payload = payload.split(",").map((el: any) => {
                    return {attributeName: "QuoteId", value: el.trim()}
                })
            }
            else if (payload?.length == 0)
            {
                dismissPanel()
                setCurrentErrorMessageWithTimeout("Please enter a valid list of Quote Ids or a single Quote Id.")
                return;
            }
            else
            {
                payload = [{"attributeName": "QuoteId", "value": payload}]
            }
        
            let filteredPayload: any[] = [];
        
            filteredPayload = payload.filter((el: any) => trackerList?.findIndex((el2: any) => el2.quoteId == el.value) === -1)
        
            if (payload.length > filteredPayload.length)
            {
                someQuotesAlreadyThere = true;
                if (filteredPayload.length == 0)
                {
                    dismissPanel()
                    setCurrentSuccessMessageWithTimeout(`Given Quotes are already being tracked!`)
                    return;
            }
            }
        
            const addNewQuoteToTrackSetter = (param: any) => {
                setBusinessTrackerData(param);
                if (trackerState?.id === 'businessProposals')
                {
                    triggerBizTrackedQuotesRefresh(!bizTrackedQuotesRefresh)
                }
                else
                {
                    triggerTrackedQuotesRefresh(!trackedQuotesRefresh)
                }
                
                const distinctValues = filteredPayload?.reduce((acc: any[], el: any) => {
                    if (!acc.includes(el.value)) {
                        acc.push(el.value);
                    }
                    return acc;
                }, []);
                let validDistinctValues: any[] = [];
                let invalidDistinctValues: any[] = [];
                
                distinctValues.forEach((el: any) => {
                if (param.rules.findIndex((el2: any) => el2.value == el) > -1)
                {
                    validDistinctValues = [el, ...validDistinctValues]
                } else {
                    invalidDistinctValues = [el, ...invalidDistinctValues]
                }
            });
        
            var updateMessage = validDistinctValues?.map((el: any) => el).join(", ");
            var warningMessage = invalidDistinctValues?.map((el: any) => el).join(", ");
        
            dismissPanel()
            if (someQuotesAlreadyThere && validDistinctValues?.length > 0)
            {
            setCurrentSuccessMessageWithTimeout(`${updateMessage} were added successfully! (Some Quotes are already in the list.)`)
            } else if (validDistinctValues?.length > 0) {
            setCurrentSuccessMessageWithTimeout(`${updateMessage} were added successfully!`)
            } else {
            if (someQuotesAlreadyThere)
            {
                setCurrentErrorMessageWithTimeout("Some Quotes are already in the list, no *new* Quotes added to the list.")
            }
            else {
                setCurrentErrorMessageWithTimeout("No Quotes added to the list.")
            }
            }
        
            if (invalidDistinctValues?.length > 0)
            {
            setCurrentWarningMessage(`Unable to add ${warningMessage}.`)
            }
        
            return;
        }
        
        var addNewQuotesUrl = "/v1/quotetracker/quotes/";
        if (trackerState?.id === 'businessProposals')
        {
            addNewQuotesUrl = "/v1/admin/quotes/";
            addNewQuotesUrl += trackerState?.id;
        }
        else
        {
            addNewQuotesUrl += trackerState?.id;
        }
        
        quoteTimelineService.sendCall(addNewQuotesUrl, quoteTimelineService.generateCallOptions(accessToken, JSON.stringify(filteredPayload), "POST"), addNewQuoteToTrackSetter);
    }

    const RemoveQuoteFromBusinessTrackerList = (quoteId: any) => {
        const addNewQuoteToTrackPayload = [{attributeName: "QuoteId", value: quoteId}];
        
        const removeQuoteFromTrackerList = (param: any) => {
            triggerBizTrackedQuotesRefresh(!bizTrackedQuotesRefresh)
            setCurrentSuccessMessageWithTimeout(`${quoteId} has been removed from the list to track!`)
        };

        var createTrackerUrl = `/v1/admin/quotes/businessProposals`;
        quoteTimelineService.sendCall(createTrackerUrl, quoteTimelineService.generateCallOptions(accessToken, JSON.stringify(addNewQuoteToTrackPayload), "DELETE"), removeQuoteFromTrackerList);
    }

    const businessTrackerListOfQuotesSetter = (param: any) => {
        setBusinessTrackerList(param);
    }

    useEffect(() => {
        if (businessTrackerData?.id === '' || accessToken == null)
        {
            return;
        }

        var getListOfQuotes = `/v1/quotetracker/quotes/businessProposals`; 
        quoteTimelineService.sendCall(getListOfQuotes, quoteTimelineService.generateCallOptions(accessToken, "", "GET"), businessTrackerListOfQuotesSetter);
    }, [businessTrackerData.id, accessToken, bizTrackedQuotesRefresh]);

    useEffect(() => {
        document.title = "Business-tracked proposals";
    }, [])
    
    return (
        <div style={{ position: 'relative' }}>
        {currentlyFetchingData && 
            <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
                zIndex: 1 // ensure the overlay is on top
            }}>
                <ProgressRing indeterminate={true} />
            </div>
        }
        <div style={{ opacity: currentlyFetchingData ? 0.5 : 1 }}> {/* Diminished view when fetching data */}
            <div style={{marginBottom:20, textAlign:'right'}}>
                {accessToken !== null && TokenHasRole(accessToken, AppRoles.Admin) ? <PrimaryButton text="Add New Quotes"  onClick={openAddQuotePanel} /> : null}
            </div>
            <GenericTrackerPage
                hash="#/business"
                userHasAdminRole={accessToken !== null && TokenHasRole(accessToken, AppRoles.Admin)}
                removeQuoteFromList={RemoveQuoteFromBusinessTrackerList}
                listOfQuotes={businessTrackerList}
                trackFromBusinessList={(quoteId: any) => AddNewBusinessQuoteToTrack(
                    quoteTimelineService,
                    quoteId,
                    myTrackerData,
                    myTrackerList,
                    accessToken,
                    dismissAddQuotePanel,
                    setCurrentSuccessMessageWithTimeout,
                    setCurrentErrorMessageWithTimeout,
                    setCurrentWarningMessageWithTimeout)}
            />
        </div>
            <Panel
            headerText="Add New Quotes"
            isOpen={isAddQuotePanelOpen}
            onDismiss={dismissAddQuotePanel}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
          >
            <Stack>
              <StackItem>
                <TextField label="Enter Quote Ids (comma separated):" onChange={(e) => setCurrentAddQuoteTextInput(e.currentTarget.value)} value={currentAddQuoteTextInput} />
              </StackItem>
              <StackItem style={{justifyContent: 'end', display: 'flex'}}>
                <PrimaryButton text={"Add New Quotes"} style={{marginTop: 15}} disabled={addQuoteButtonDisabled} onClick={(e) => AddNewBusinessQuoteToTrack(
                            quoteTimelineService,
                            currentAddQuoteTextInput,
                            businessTrackerData,
                            businessTrackerList,
                            accessToken,
                            dismissAddQuotePanel,
                            setCurrentSuccessMessageWithTimeout,
                            setCurrentErrorMessageWithTimeout,
                            setCurrentWarningMessageWithTimeout)} />
              </StackItem>
            </Stack>
          </Panel>
        </div>
    )
}