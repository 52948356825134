
import { callout, buttonGroup, option, badge, dialog, combobox, accordion, textField, accordionItem, button, checkbox, checkboxGroup, commandBar, createScope, dataGrid, dropdown, filterPill, flyInPanel, icon, menu, menuItem, pageTitle, pagination, progress, radio, radioGroup } from "@harmony/enablers/react";
import React, { useEffect, useRef, useContext, useState } from "react";
import { QuoteTrackerHttpClient } from "../services/QuoteTrackerHttpClient";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../padTop.css';
import { PageLayoutContext } from "./PageLayout";

const scope = createScope({
    reactInstance: React
});

const fieldsForFilter = [
    {
        "name": "QuoteId",
        "possibleValues": []
    },
    {
        "name": "OpportunityId",
        "possibleValues": []
    },
    {
        "name": "ModifiedDate",
        "possibleValues": []
    },
    {
        "name": "TrafficType",
        "possibleValues": [
            "Live",
            "Test"
        ]
    },
    {
        "name": "CreationSource",
        "possibleValues": [
            'Proposal Management Workspace',
            'Field',
            'Quote Service',
            'TestField',
            'Others'
        ]
    },
    {
        "name": "TransactionType",
        "possibleValues": [
            "UserPurchase",
            "Referral",
            "SystemBilling",
            "SystemPurchase",
            "Unknown"
        ]
    },
    {
        "name": "QuoteStatus",
        "possibleValues": [
            "Unknown",
            "Draft",
            "Submitted",
            "Active",
            "Rejected",
            "Deleted",
            "Expired",
            "PurchaseInProgress",
            "Completed"
        ]
    },
    {
        "name": "LineItemCount",
        "possibleValues": []
    },
    {
        "name": "DealType",
        "possibleValues": [
            "China 21V",
            "Indirect CSP",
            "Jio CSP",
            "MCA",
            "CAPT",
            "OuterBanks",
            "RockCreek",
            "UKCCF"
        ]
    },
    {
        "name": "Channel",
        "possibleValues": [
            "CSP",
            "DirectOnly",
            "Indirect",
            "ISV"
        ]
    },
    {
        "name": "Scenario",
        "possibleValues": [
            "ACO",
            "ACO Non-CSP",
            "Azure Monetory Commit",
            "CSSC",
            "Future Discount",
            "ECIF",
            "Enterprise Support",
            "Onetime Discount",
            "Partner",
            "Partner Customer Asset",
            "Product Discount Fulfilment",
            "Specialized Offer",
            "SSC"
        ]
    },
    {
        "name": "AssignedTo",
        "possibleValues": [
            "Customer",
            "Field",
            "Internal",
            "InternalDependency",
            "Partner",
            "Unknown"
        ]
    },
    {
        "name": "QuoteExpireIn",
        "possibleValues": [
            "1h",
            "6h",
            "12h",
            "1d",
            "7d",
            "14d",
            "30d",
            "60d",
            "90d",
            "180d",
            "365d"
        ]
    },
    {
        "name": "ValidateDate",
        "possibleValues": []
    },
    {
        "name": "ProductType",
        "possibleValues": [
            "AzureAccessPass",
            "AzureCommitmentDiscount",
            "AzureCredits",
            "AzureFamilyDiscount",
            "AzureMonetaryCredit",
            "AzureSavingsPlanDiscount",
            "AzureSupport",
            "CommitmentToConsume",
            "DiscountFulfillment",
            "DualUseRights",
            "EnterpriseSupport",
            "M365Copilot",
            "M365CopilotPass",
            "VariablePricingAndPayment",
            "Private-Equity-Discount"
        ]
    },
    {
        "name": "AcceptanceMode",
        "possibleValues": [
            "ClickToAccept",
            "ESignOffline",
            "ESignEmbedded",
            "PhysicalSign",
            "Offline"
        ]
    },
    {
        "name": "ProposalValue",
        "possibleValues": []
    },
    {
        "name": "PurchaseableCount",
        "possibleValues": []
    },
    {
        "name": "PriceableCount",
        "possibleValues": []
    },
    {
        "name": "FutureDiscountCount",
        "possibleValues": []
    },
    {
        "name": "NewCustomTermCount",
        "possibleValues": []
    },
    {
        "name": "NeedAttention",
        "possibleValues": [
            "Yes",
            "No",
            "Maybe"
        ]
    }
];

const getTextValForMonetaryVal = (val: number) => {
    if (val >= 1000000000)
    {
        return `${(val / 1000000000).toFixed(2)} bil`;
    }
    else if (val >= 1000000)
    {
        return `${(val / 1000000).toFixed(2)} mil`;
    }
    else if (val >= 1000)
    {
        return `${(val / 1000).toFixed(2)} k`;
    }
    else
    {
        return `${val}`;
    }
}

const getRealTextValForMonetaryVal = (val: number) => {
    return val;
}

const creationSourceValMapping: any = {
    'Proposal Management Workspace': 'QuoteCenter',
    'Quote Service': 'QuoteService',
    'Field': 'L2OField',
    'TestField': 'L2OTestField',
    'Others': 'Unknown'
}

const TextField = scope.forReact(textField);
const ButtonGroup = scope.forReact(buttonGroup);
const Option = scope.forReact(option);
const Combobox = scope.forReact(combobox);
const Callout = scope.forReact(callout);
const Badge = scope.forReact(badge);
const Dialog = scope.forReact(dialog);
const RadioGroup = scope.forReact(radioGroup);
const Pagination = scope.forReact(pagination);
const Radio = scope.forReact(radio);
const Checkbox = scope.forReact(checkbox);
const CheckboxGroup = scope.forReact(checkboxGroup);
const Button = scope.forReact(button);
const DataGrid = scope.forReact(dataGrid);
const PageTitle = scope.forReact(pageTitle);
const CommandBar = scope.forReact(commandBar);
const Dropdown = scope.forReact(dropdown);
const Icon = scope.forReact(icon);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const FlyInPanel = scope.forReact(flyInPanel);
const FilterPill = scope.forReact(filterPill);
const Accordion = scope.forReact(accordion);
const AccordionItem = scope.forReact(accordionItem);
const Progress = scope.forReact(progress);

const styles: {
    [key: string]: React.CSSProperties,
  } = {
    accordionList: {
        margin: '4px 4px',
        overflow: 'auto',
        inset: 0,
        position: 'absolute',
        borderTop: '0px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 'var(--he-spacing-x-small)',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    dropdownContents: {
      minWidth: '300px',
      padding: 'var(--he-spacing-medium)',
    },
    dropdownButtons: {
      marginTop: 'var(--he-spacing-x-large)',
      display: 'flex',
      flexDirection: 'row',
      gap: 'var(--he-spacing-x-small)',
    },
    resetButton: {
      marginLeft: 'auto',
    },
  };

export const ViewAllProposals = (props: any) => {
    const { setCurrentErrorMessageWithTimeout, AddNewQuoteToTrack, RemoveQuoteFromTrackerList, accessToken, myTrackerList } = useContext(PageLayoutContext) as any;

    // filters
    const [statusValuesAsArray, setStatusValuesAsArray] = React.useState<any>(null);
    const [statusValuesAsArrayPill, setStatusValuesAsArrayPill] = React.useState<any>(null);

    const [modifiedDate, setModifiedDate] = React.useState<string>(new Date(new Date().setUTCHours(new Date().getUTCHours() - 720)).toISOString());

    const [totalDealValue2, setTotalDealValue2] = React.useState<any>(null);
    const [totalDealValue2Pill, setTotalDealValue2Pill] = React.useState<any>(null);

    const [idValue, setIdValue] = React.useState<any>(null);
    const [idValuePill, setIdValuePill] = React.useState<any>(null);

    const [idType, setIdType] = React.useState<string>("QuoteId");
    const [idTypePill, setIdTypePill] = React.useState<string>("QuoteId");

    const [expirationFilterVal, setExpirationFilterVal] = React.useState<any>(null);
    const [expirationFilterValPill, setExpirationFilterValPill] = React.useState<any>(null);

    const [assignedToValuesAsArray, setAssignedToValuesAsArray] = React.useState<any>(null);
    const [assignedToValuesAsArrayPill, setAssignedToValuesAsArrayPill] = React.useState<any>(null);

    const [channelValuesAsArray, setChannelValuesAsArray] = React.useState<any>(null);
    const [channelValuesAsArrayPill, setChannelValuesAsArrayPill] = React.useState<any>(null);

    const [dealTypeValuesAsArray, setDealTypeValuesAsArray] = React.useState<any>(null);
    const [dealTypeValuesAsArrayPill, setDealTypeValuesAsArrayPill] = React.useState<any>(null);

    const [totalLineItemsValue, setTotalLineItemsValue] = React.useState<any>(null);
    const [totalLineItemsValuePill, setTotalLineItemsValuePill] = React.useState<any>(null);

    const [priceableValue, setPriceableValue] = React.useState<any>(null);
    const [priceableValuePill, setPriceableValuePill] = React.useState<any>(null);

    const [purchasableValue, setPurchasableValue] = React.useState<any>(null);
    const [purchasableValuePill, setPurchasableValuePill] = React.useState<any>(null);

    const [futureDiscountValue, setFutureDiscountValue] = React.useState<any>(null);
    const [futureDiscountValuePill, setFutureDiscountValuePill] = React.useState<any>(null);

    const [newCustomTermValue, setNewCustomTermValue] = React.useState<any>(null);
    const [newCustomTermValuePill, setNewCustomTermValuePill] = React.useState<any>(null);

    const [scenarioValuesAsArray, setScenarioValuesAsArray] = React.useState<any>(null);
    const [scenarioValuesAsArrayPill, setScenarioValuesAsArrayPill] = React.useState<any>(null);

    const [productTypeValuesAsArray, setProductTypeValuesAsArray] = React.useState<any>(null);
    const [productTypeValuesAsArrayPill, setProductTypeValuesAsArrayPill] = React.useState<any>(null);

    const [acceptanceModeValuesAsArray, setAcceptanceModeValuesAsArray] = React.useState<any>(null);
    const [acceptanceModeValuesAsArrayPill, setAcceptanceModeValuesAsArrayPill] = React.useState<any>(null);

    const [futureTransactionDateValue, setFutureTransactionDateValue] = React.useState<any>(null);
    const [futureTransactionDateValuePill, setFutureTransactionDateValuePill] = React.useState<any>(null);

    const [creationSourceValuesAsArray, setCreationSourceValuesAsArray] = React.useState<any>(null);
    const [creationSourceValuesAsArrayPill, setCreationSourceValuesAsArrayPill] = React.useState<any>(null);

    const [trafficSource, setTrafficSource] = React.useState<any>(null);
    const [trafficSourcePill, setTrafficSourcePill] = React.useState<any>(null);

    const [transactionTypeValuesAsArray, setTransactionTypeValuesAsArray] = React.useState<any>(null);
    const [transactionTypeValuesAsArrayPill, setTransactionTypeValuesAsArrayPill] = React.useState<any>(null);

    const [anyActiveFilters, setAnyActiveFilters] = React.useState(false);
    const [anyActivePills, setAnyActivePills] = React.useState(false);

    const [expirationDisplay, setExpirationDisplay] = React.useState<any>("Select a value");
    const [expirationDisplayPill, setExpirationDisplayPill] = React.useState<any>(null);

    const [refreshValDisplay, setRefreshValDisplay] = React.useState("3 minutes");
    const [modifiedDateDisplay, setModifiedDateDisplay] = React.useState("Last 30 days");
    const [currentRefreshIntervalId, setCurrentRefreshIntervalId] = React.useState<any>(null);

    const [availableFilters, setAvailableFilters] = React.useState(fieldsForFilter);
    
    const [data, setData] = React.useState<any>({rows: [], columns: [
        { key: "ProposalName", sortable: true, content: "Proposal name", name: "ProposalName", field: "QuoteName", display: { minWidth: '400px' },  },
        { key: "ProposalId", sortable: true, content: "Proposal ID", name: "ProposalId", field: "QuoteId", display: { minWidth: '100px' } },
        { key: "Status", sortable: true, content: "Status", name: "Status", field: "Status", display: { minWidth: '200px' } },
        { key: "NeedsAttention", sortable: true, content: "Needs attention?", name: "NeedsAttention", field: "NeedAttention", display: { minWidth: '100px' } },
        { key: "ExpirationDate", sortable: true, content: "Expiration date", name: "ExpirationDate", field: "ExpirationDate", display: { minWidth: '150px' } },
        { key: "ModifiedDate", sortable: true, content: "Modified date", name: "ModifiedDate", field: "ModifiedDate", display: { minWidth: '150px' } },
        { key: "AssignedTo", sortable: true, content: "Assigned to", name: "AssignedTo", field: "AssignedTo", display: { minWidth: '100px' } },
        { key: "EstimatedDealValue", sortable: true, content: "Estimated deal value", name: "EstimatedDealValue", field: "EstimatedDealValue", display: { minWidth: '100px' } },
        { key: "TotalPrice", sortable: true, content: "Total price", name: "TotalPrice", field: "TotalPrice", display: { minWidth: '100px' } },
        { key: "MaccInUsd", sortable: true, content: "MACC in USD", name: "MaccInUsd", field: "MACCinUSD", display: { minWidth: '100px' } },
        { key: "BillingCurrency", sortable: true, content: "Billing currency", name: "BillingCurrency", field: "BillingCurrency", display: { minWidth: '100px' } },
        { key: "AgreementStatus", sortable: true, content: "Agreement status", name: "AgreementStatus", field: "AgreementStatus", display: { minWidth: '100px' } },
        { key: "AgreementId", sortable: true, content: "Agreement ID", name: "AgreementId", field: "AgreementId", display: { minWidth: '100px' } },
        { key: "AgreementAcceptanceMode", sortable: true, content: "Agreement acceptance mode", name: "AgreementAcceptanceMode", field: "AgreementAcceptanceMode", display: { minWidth: '100px', maxWidth: 200 } },
        { key: "IsCAPT", sortable: true, content: "CAPT?", name: "IsCAPT", field: "IsCAPT", display: { minWidth: '200px' } },
        { key: "LineItemsCount", sortable: true, content: "Line item count", name: "LineItemsCount", field: "LineItemsCount", display: { minWidth: '200px' } },
        { key: "PurchasableCount", sortable: true, content: "Purchasable count", name: "PurchasableCount", field: "PurchaseableCount", display: { minWidth: '200px' } },
        { key: "PriceableCount", sortable: true, content: "Priceable count", name: "PriceableCount", field: "PriceableCount", display: { minWidth: '200px' } },
        { key: "FutureDiscountCount", sortable: true, content: "Future discount count", name: "FutureDiscountCount", field: "FutureDiscountCount", display: { minWidth: '200px' } },
        { key: "NewCustomTermCount", sortable: true, content: "New custom term count", name: "NewCustomTermCount", field: "NewCustomTermCount", display: { minWidth: '200px' } },
        { key: "MSContact", sortable: true, content: "MS Contact", name: "MSContact", field: "MSContact", display: { minWidth: '200px' } },
        { key: "SoldToAccountId", sortable: true, content: "Sold to Account ID", name: "SoldToAccountId", field: "SoldToAccountId", display: { minWidth: '200px' } },
        { key: "SoldToOrganizationId", sortable: true, content: "Sold to Organization ID", name: "SoldToOrganizationId", field: "SoldToOrganizationId", display: { minWidth: '200px' } },
        { key: "BillToAccountId", sortable: true, content: "Bill to Account ID", name: "BillToAccountId", field: "BillToAccountId", display: { minWidth: '200px' } },
        { key: "BillToOrganizationId", sortable: true, content: "Bill to Organization ID", name: "BillToOrganizationId", field: "BillToOrganizationId", display: { minWidth: '200px' } },
        { key: "BillingGroupId", sortable: true, content: "Billing group ID", name: "BillingGroupId", field: "BillingGroupId", display: { minWidth: '200px' } },
        { key: "EndCustomerAccountId", sortable: true, content: "End customer Account ID", name: "EndCustomerAccountId", field: "EndCustomerAccountId", display: { minWidth: '200px' } },
        { key: "EndCustomerOrganizationId", sortable: true, content: "End customer Organization ID", name: "EndCustomerOrganizationId", field: "EndCustomerOrganizationId", display: { minWidth: '200px' } },
        { key: "TotalPriceInListPrice", sortable: true, content: "List price total", name: "TotalPriceInListPrice", field: "TotalPriceInListPrice", display: { minWidth: '150px' } },
        { key: "TotalPriceInRetailPrice", sortable: true, content: "Retail price total", name: "TotalPriceInRetailPrice", field: "TotalPriceInRetailPrice", display: { minWidth: '150px' } },
        { key: "TotalPriceInDiscountedRetailPrice", sortable: true, content: "Discounted retail price total", name: "TotalPriceInDiscountedRetailPrice", field: "TotalPriceInDiscountedRetailPrice", display: { minWidth: '150px' } },
        { key: "ApprovalStatus", sortable: true, content: "Approval status", name: "ApprovalStatus", field: "ApprovalStatus", display: { minWidth: '200px' } },
        { key: "ApprovalWorkflow", sortable: true, content: "Approval workflow", name: "ApprovalWorkflow", field: "ApprovalWorkflow", display: { minWidth: '200px' } },
        { key: "TransactionType", sortable: true, content: "Transaction type", name: "TransactionType", field: "TransactionType", display: { minWidth: '200px' } },
        { key: "TransactionModel", sortable: true, content: "Transaction model", name: "TransactionModel", field: "TransactionModel", display: { minWidth: '200px' } },
        { key: "Market", sortable: true, content: "Market", name: "Market", field: "Market", display: { minWidth: '150px' } },
        { key: "OpportunityId", sortable: true, content: "Opportunity ID", name: "OpportunityId", field: "OpportunityId", display: { minWidth: '200px' } },
        { key: "VLAgreementNumber", sortable: true, content: "VL agreement number", name: "VLAgreementNumber", field: "VLAgreementNumber", display: { minWidth: '200px' } },
        { key: "CreatedDate", sortable: true, content: "Created date", name: "CreatedDate", field: "CreatedDate", display: { minWidth: '200px' } },
        { key: "SubmittedDate", sortable: true, content: "Submitted date", name: "SubmittedDate", field: "SubmittedDate", display: { minWidth: '200px' } },
        { key: "LastPricedDate", sortable: true, content: "Last priced date", name: "LastPricedDate", field: "LastPricedDate", display: { minWidth: '200px' } },
        { key: "MonthlyCreditLimit", sortable: true, content: "Monthly credit limit", name: "MonthlyCreditLimit", field: "MonthlyCreditLimit", display: { minWidth: '200px' } },
        { key: "ExchangeRateDate", sortable: true, content: "Exchange rate date", name: "ExchangeRateDate", field: "ExchangeRateDate", display: { minWidth: '200px' } },
        { key: "ScenarioName", sortable: true, content: "Business scenarios", name: "ScenarioName", field: "ScenarioName", display: { minWidth: '200px' } },
        { key: "CreatedAppSourceId", sortable: true, content: "Created app source", name: "CreatedAppSourceId", field: "CreatedAppSourceId", display: { minWidth: '200px' } },
        {
            field: `icon`,
            content: ``,
            sortable: false,
            display: {
                width: '10px',
                maxWidth: '10px'
            }
        }
    ]});
    const [filterPanelOpen, setFilterPanelOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    var quoteTimelineService = new QuoteTrackerHttpClient(setCurrentErrorMessageWithTimeout, setLoading, console.log, console.log);

    const handleStatusCheckboxGroupChange = (e: any) => {
        setStatusValuesAsArray(e.target.valueAsArray);
    };

    const handleAssignedToCheckboxGroupChange = (e: any) => {
        setAssignedToValuesAsArray(e.target.valueAsArray);
    };

    const handleChannelCheckboxGroupChange = (e: any) => {
        setChannelValuesAsArray(e.target.valueAsArray);
    };

    const handleDealTypeCheckboxGroupChange = (e: any) => {
        setDealTypeValuesAsArray(e.target.valueAsArray);
    };

    const handleScenarioCheckboxGroupChange = (e: any) => {
        setScenarioValuesAsArray(e.target.valueAsArray);
    };

    const handleProductTypeCheckboxGroupChange = (e: any) => {
        setProductTypeValuesAsArray(e.target.valueAsArray);
    };

    const handleAcceptanceModeCheckboxGroupChange = (e: any) => {
        setAcceptanceModeValuesAsArray(e.target.valueAsArray);
    };

    const handleCreationSourceCheckboxGroupChange = (e: any) => {
        setCreationSourceValuesAsArray(e.target.valueAsArray);
    };

    const handleTransactionTypeCheckboxGroupChange = (e: any) => {
        setTransactionTypeValuesAsArray(e.target.valueAsArray);
    };
    
    const handleDateFormatShow = () => {
        let futureTransactionSplit = futureTransactionDateValuePill.split('-');
        return `${ futureTransactionSplit[1]}/${futureTransactionSplit[2]}/${futureTransactionSplit[0]}`
    }

    const handleSortNeedAttention = (e: any) => {
        const { isAscending, sortBy } = e.detail;
        
        const sortData = (a: any, b: any) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];

            if (sortBy === 'NeedAttention') {
                const order = ['Unknown', 'No', 'Maybe', 'Yes']; // Define your order of precedence
                const aIndex = order.indexOf(a.NeedAttention);
                const bIndex = order.indexOf(b.NeedAttention);
    
                if (aIndex === bIndex) return 0;
                return isAscending ? (aIndex < bIndex ? 1 : -1) : (aIndex > bIndex ? 1 : -1);
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                // Check if the strings are dates
                const aDate = new Date(aValue);
                const bDate = new Date(bValue);
                if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
                    // Check if the strings are dates
                    const aDate = new Date(aValue);
                    const bDate = new Date(bValue);
                    if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
                        return isAscending ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
                    }
                    return isAscending ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
                }
                return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                return isAscending ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'object' && typeof bValue === 'object') {
                // Handle integer fields represented as objects
                if (!aValue.hasOwnProperty('value')) {
                    aValue.value = 0;
                }

                if (!bValue.hasOwnProperty('value')) {
                    bValue.value = 0;
                }

                if (aValue.hasOwnProperty('value') && bValue.hasOwnProperty('value')) {
                    return isAscending ? aValue.value - bValue.value : bValue.value - aValue.value;
                }
            } else {
                // Add more custom sorting logic for other data types if needed
                return 0;
            }
        };
    
        setData({
            rows: data.rows.sort(sortData),
            columns: data.columns
        });
    };

    const [filterDropdownStates, setFilterDropdownStatesInner] = React.useState<any>({
        status: false,
        totalDealValue: false,
        id: false,
        expiration: false,
        assignedTo: false,
        channel: false,
        dealType: false,
        totalLineItems: false,
        priceable: false,
        purchasable: false,
        futureDiscount: false,
        newCustomTerm: false,
        scenario: false,
        productType: false,
        acceptanceMode: false,
        futureTransactionDate: false,
        creationSource: false,
        trafficSource: false,
        transactionType: false
    });

    const setFilterDropdownStates = (data: any, resetTemps: any = false) => {
        setFilterDropdownStatesInner(data);
        if (resetTemps)
        {
            reApplyPillsToTemps();
        }
    }

    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pagedRows, setPagedRows] = React.useState<any[]>([]);
    const pageSize = 15;
    const pageArray = () => {
        const pagedRows = data?.rows?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        const pageCount = Math.ceil(data?.rows?.length / pageSize);
        
        setPagedRows(pagedRows);
        setPageCount(pageCount);
    };

    const handlePageChange = (e: any) => {
        setPageNumber(e.detail);
    };

    useEffect(pageArray, [data, pageNumber, pageSize]);

    const setFiltersFromUrl = (searchParamsCustom: any) => {
        let areThereAnyFilters = false;
        
        if (searchParamsCustom.get('status') !== null)
        {
            setStatusValuesAsArray(searchParamsCustom.get('status')?.split(',') ?? []);
            setStatusValuesAsArrayPill(searchParamsCustom.get('status')?.split(',') ?? []);
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('totalDealValue') !== null)
        {
            setTotalDealValue2(searchParamsCustom.get('totalDealValue') || "0");
            setTotalDealValue2Pill(searchParamsCustom.get('totalDealValue') || "0");
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('idValue') !== null && searchParamsCustom.get('idType') !== null)
        {
            setIdValue(searchParamsCustom.get('idValue') ?? "");
            setIdType(searchParamsCustom.get('idType') ?? "QuoteId");

            setIdValuePill(searchParamsCustom.get('idValue') ?? "");
            setIdTypePill(searchParamsCustom.get('idType') ?? "QuoteId");
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('expirationFilterVal') !== null && searchParamsCustom.get('expirationDisplay') !== null)
        {
            setExpirationFilterVal(searchParamsCustom.get('expirationFilterVal') ?? "");
            setExpirationDisplay(searchParamsCustom.get('expirationDisplay') ?? "");

            setExpirationFilterValPill(searchParamsCustom.get('expirationFilterVal') ?? "");
            setExpirationDisplayPill(searchParamsCustom.get('expirationDisplay') ?? "");
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('assignedTo') !== null)
        {
            setAssignedToValuesAsArray(searchParamsCustom.get('assignedTo')?.split(',') ?? []);
            setAssignedToValuesAsArrayPill(searchParamsCustom.get('assignedTo')?.split(',') ?? []);
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('channel') !== null)
        {
            setChannelValuesAsArray(searchParamsCustom.get('channel')?.split(',') ?? []);
            setChannelValuesAsArrayPill(searchParamsCustom.get('channel')?.split(',') ?? []);
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('dealType') !== null)
        {
            setDealTypeValuesAsArray(searchParamsCustom.get('dealType')?.split(',') ?? []);
            setDealTypeValuesAsArrayPill(searchParamsCustom.get('dealType')?.split(',') ?? []);
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('totalLineItems') !== null)
        {
            setTotalLineItemsValue(parseInt(searchParamsCustom.get('totalLineItems') || "0"));
            setTotalLineItemsValuePill(parseInt(searchParamsCustom.get('totalLineItems') || "0"));
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('priceable') !== null)
        {
            setPriceableValue(parseInt(searchParamsCustom.get('priceable') || "0"));
            setPriceableValuePill(parseInt(searchParamsCustom.get('priceable') || "0"));
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('purchasable') !== null)
        {
            setPurchasableValue(parseInt(searchParamsCustom.get('purchasable') || "0"));
            setPurchasableValuePill(parseInt(searchParamsCustom.get('purchasable') || "0"));
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('futureDiscount') !== null)
        {
            setFutureDiscountValue(parseInt(searchParamsCustom.get('futureDiscount') || "0"));
            setFutureDiscountValuePill(parseInt(searchParamsCustom.get('futureDiscount') || "0"));
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('newCustomTerm') !== null)
        {
            setNewCustomTermValue(parseInt(searchParamsCustom.get('newCustomTerm') || "0"));
            setNewCustomTermValuePill(parseInt(searchParamsCustom.get('newCustomTerm') || "0"));
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('scenario') !== null)
        {
            setScenarioValuesAsArray(searchParamsCustom.get('scenario')?.split(',') ?? []);
            setScenarioValuesAsArrayPill(searchParamsCustom.get('scenario')?.split(',') ?? []);
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('productType') !== null)
        {
            setProductTypeValuesAsArray(searchParamsCustom.get('productType')?.split(',') ?? []);
            setProductTypeValuesAsArrayPill(searchParamsCustom.get('productType')?.split(',') ?? []);
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('acceptanceMode') !== null)
        {
            setAcceptanceModeValuesAsArray(searchParamsCustom.get('acceptanceMode')?.split(',') ?? []);
            setAcceptanceModeValuesAsArrayPill(searchParamsCustom.get('acceptanceMode')?.split(',') ?? []);
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('timeTravel') !== null)
        {
            setFutureTransactionDateValue(searchParamsCustom.get('timeTravel') ?? "");
            setFutureTransactionDateValuePill(searchParamsCustom.get('timeTravel') ?? "");
            areThereAnyFilters = true;
        }

        if (searchParamsCustom.get('creationSource') !== null)
        {
            setCreationSourceValuesAsArray(searchParamsCustom.get('creationSource')?.split(',') ?? []);
            setCreationSourceValuesAsArrayPill(searchParamsCustom.get('creationSource')?.split(',') ?? []);
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('trafficSource') !== null)
        {
            setTrafficSource(searchParamsCustom.get('trafficSource') ?? "");
            setTrafficSourcePill(searchParamsCustom.get('trafficSource') ?? "");
            areThereAnyFilters = true;
        }
        
        if (searchParamsCustom.get('transactionType') !== null)
        {
            setTransactionTypeValuesAsArray(searchParamsCustom.get('transactionType')?.split(',') ?? []);
            setTransactionTypeValuesAsArrayPill(searchParamsCustom.get('transactionType')?.split(',') ?? []);
            areThereAnyFilters = true;
        }
    }

    useEffect(() => {
        document.title = "View all proposals";
        if ((location.state as any)?.searchParams?.length > 0 || location.search?.length > 0)
        {
            if ((location.state as any)?.searchParams?.length > 0)
            {
                setFiltersFromUrl(new URLSearchParams((location.state as any)?.searchParams));
            } else {
                setFiltersFromUrl(new URLSearchParams(location.search));
            }   
        }
        else
        {
            clearFilters();
        }
    }, []);

    const generateActiveFiltersPills = () => {
        if (anyActivePills)
        {
            return <>
                {statusValuesAsArrayPill != null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['status']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, status: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, status: true})}  style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={statusValuesAsArrayPill}>
                    Status:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={statusValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleStatusCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "QuoteStatus")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(statusValuesAsArray?.length === statusValuesAsArrayPill?.length && statusValuesAsArrayPill?.filter((el: any) => statusValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || statusValuesAsArray?.length === 0}
                        onClick={() => {setStatusValuesAsArrayPill(statusValuesAsArray);setFilterDropdownStates({...filterDropdownStates, status: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, status: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                    </Dropdown>
                    <FilterPill clear onClick={(e: any) => { setStatusValuesAsArray(null);setStatusValuesAsArrayPill(null); }} />
                </ButtonGroup> : null}
                {(
                totalDealValue2Pill !== null ? 
                    <ButtonGroup style={{marginTop: 5}}><Dropdown onHeDropdownRequestClose={(e: any) => {e?.detail?.source === "change" && e.preventDefault()}} className="pad-top" open={filterDropdownStates['totalDealValue']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, totalDealValue: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, totalDealValue: true})}>
                    <FilterPill slot="trigger" style={{marginLeft: 5}}>
                    Total value: <span style={{fontWeight: 600}}>{totalDealValue2Pill !== "" ? getTextValForMonetaryVal(parseInt(totalDealValue2Pill)) : ""}</span>
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <div style={{fontSize: 14, fontWeight: 600}}>Value of proposal</div>
                        <div style={{fontSize: 13}}>Custom values may be entered.</div>
                            <Combobox
                                autocomplete="inline"
                                value={totalDealValue2}
                                onHeInput={(e: any) => {
                                    setTotalDealValue2(e.detail)}}
                                onHeChange={(e: any) => {
                                    setTotalDealValue2(e.target.value)}}
                                style={{width: '100%', marginTop: 5}}
                                placeholder="Select a value"
                                freeform={true}
                            >
                                <Option value="0">0</Option>
                                <Option value="100000">100,000</Option>
                                <Option value="500000">500,000</Option>
                                <Option value="1000000">1 million</Option>
                                <Option value="50000000">50 million</Option>
                                <Option value="100000000">100 million</Option>
                                <Option value="250000000">250 million</Option>
                                <Option value="500000000">500 million</Option>
                                <Option value="1000000000">1 billion</Option>
                            </Combobox>
                    <div style={styles.dropdownButtons}>
                        <Button disabled={totalDealValue2 === totalDealValue2Pill || totalDealValue2 === ''} onClick={(e: any) => {setTotalDealValue2Pill(totalDealValue2);setFilterDropdownStates({...filterDropdownStates, totalDealValue: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, totalDealValue: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                    </Dropdown>
                    <FilterPill clear onClick={(e: any) => { setTotalDealValue2(null);setTotalDealValue2Pill(null); }} />
                </ButtonGroup>
                     : null
                )}
                {(
                    idValuePill != null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['id']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, id: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, id: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>{idTypePill === "QuoteId" ? "Proposal" : "Opportunity" } ID: <span style={{fontWeight: 600}}>{idValuePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField
                            required={true}
                            label={idTypePill === "QuoteId" ? "Proposal ID" : "Opportunity ID"}
                            value={idValue}
                            placeholder={`Enter a ${idTypePill === "QuoteId" ? "proposal ID" : "opportunity ID"}`}
                            onHeInput={(e: any) => setIdValue(e.target.value)} />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={idValuePill === idValue || idValue === ''} onClick={(e: any) => {setIdValuePill(idValue);setFilterDropdownStates({...filterDropdownStates, id: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, id: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                    <FilterPill clear onClick={(e: any) => {setIdValue(null);setIdValuePill(null);setIdType('QuoteId');setIdTypePill('QuoteId')}} />
                </ButtonGroup>
                         : null
                )}
                {(
                expirationFilterValPill !== null ?
                    <ButtonGroup style={{marginTop: 5}}>
                    <Dropdown fixedPlacement className="pad-top" open={filterDropdownStates['expiration']} onHeDropdownRequestClose={(e: any) => {e?.detail?.source === "change" && e.preventDefault()}} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, expiration: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, expiration: true})}>
                    <FilterPill slot="trigger" style={{marginLeft: 5}}>
                    Expiration: <span style={{fontWeight: 600}}>{expirationDisplayPill !== "Select a value" ? expirationDisplayPill : ""}</span>
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <div style={{fontSize: 14, fontWeight: 600}}>Proposal is expiring</div>
                        <Dropdown style={{width: '100%', marginTop: 10}} fixedPlacement>
                            <Button style={{textAlign: 'left', width: '100%'}} caret slot="trigger">{expirationDisplay}</Button>
                            <Menu style={{width: '100%'}}>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '1hr')}>in 1 hour</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '6hr')}>in 6 hours</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '12hr')}>in 12 hours</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '1d')}>in 1 day</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '7d')}>in 7 days</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '14d')}>in 14 days</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '30d')}>in 30 days</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '60d')}>in 60 days</MenuItem>
                                <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '90d')}>in 90 days</MenuItem>
                            </Menu>
                        </Dropdown>
                    <div style={styles.dropdownButtons}>
                        <Button disabled={expirationFilterVal === expirationFilterValPill} onClick={() => {setExpirationFilterValPill(expirationFilterVal);setExpirationDisplayPill(expirationDisplay)}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, expiration: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                    </Dropdown>
                    <FilterPill clear onClick={(e: any) => {
                        setExpirationDisplay("Select a value");
                        setExpirationFilterVal(null);
                        setExpirationDisplayPill("Select a value");
                        setExpirationFilterValPill(null)}} />
                    </ButtonGroup>
                     : null
                )}
                {assignedToValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['assignedTo']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, assignedTo: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, assignedTo: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={assignedToValuesAsArrayPill}>
                    Assigned To:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={assignedToValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleAssignedToCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "AssignedTo")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(assignedToValuesAsArray?.length === assignedToValuesAsArrayPill?.length && assignedToValuesAsArrayPill?.filter((el: any) => assignedToValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || assignedToValuesAsArray?.length === 0}
                        onClick={() => {setAssignedToValuesAsArrayPill(assignedToValuesAsArray);setFilterDropdownStates({...filterDropdownStates, assignedTo: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, assignedTo: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setAssignedToValuesAsArray(null);setAssignedToValuesAsArrayPill(null)}} />
                </ButtonGroup>
                 : null}
                {channelValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['channel']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, channel: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, channel: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={channelValuesAsArrayPill}>
                    Channel:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={channelValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleChannelCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "Channel")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(channelValuesAsArray?.length === channelValuesAsArrayPill?.length && channelValuesAsArrayPill?.filter((el: any) => channelValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || channelValuesAsArray?.length === 0}
                        onClick={() => {setChannelValuesAsArrayPill(channelValuesAsArray);setFilterDropdownStates({...filterDropdownStates, channel: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, channel: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setChannelValuesAsArray(null);setChannelValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                {dealTypeValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['dealType']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, dealType: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, dealType: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={dealTypeValuesAsArrayPill}>
                    Deal type:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={dealTypeValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleDealTypeCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "DealType")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(dealTypeValuesAsArray?.length === dealTypeValuesAsArrayPill?.length && dealTypeValuesAsArrayPill?.filter((el: any) => dealTypeValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || dealTypeValuesAsArray?.length === 0}
                        onClick={() => {setDealTypeValuesAsArrayPill(dealTypeValuesAsArray);setFilterDropdownStates({...filterDropdownStates, dealType: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, dealType: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setDealTypeValuesAsArray(null);setDealTypeValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                {(
                    totalLineItemsValuePill > 0 ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['totalLineItems']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, totalLineItems: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, totalLineItems: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>Total line items: <span style={{fontWeight: 600}}>{totalLineItemsValuePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField
                            required={true}
                            style={{}}
                            type="number"
                            label="Total line items"
                            value={totalLineItemsValue?.toString()}
                            onHeInput={(e: any) => setTotalLineItemsValue(e.target.value)} />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={totalLineItemsValue === totalLineItemsValuePill || totalLineItemsValue === ''} onClick={(e: any) => setTotalLineItemsValuePill(totalLineItemsValue)} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, totalLineItems: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => {setTotalLineItemsValue(null);setTotalLineItemsValuePill(null)}} />
                        </ButtonGroup> : null
                )}
                {(
                    priceableValuePill > 0 ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['priceable']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, priceable: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, priceable: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>Priceable line items: <span style={{fontWeight: 600}}>{priceableValuePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField
                            required={true}
                            style={{}}
                            type="number"
                            label="Priceable line items"
                            value={priceableValue?.toString()}
                            helpText="Confidence threshold is 25 line items."
                            onHeInput={(e: any) => setPriceableValue(e.target.value)} />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={priceableValue === priceableValuePill || priceableValue === ''} onClick={(e: any) => {setPriceableValuePill(priceableValue);setFilterDropdownStates({...filterDropdownStates, priceable: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, priceable: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => {setPriceableValue(null);setPriceableValuePill(null)}} />
                        </ButtonGroup> : null
                )}
                {(
                    purchasableValuePill > 0 ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['purchasable']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, purchasable: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, purchasable: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>Purchasable line items: <span style={{fontWeight: 600}}>{purchasableValuePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField
                            required={true}
                            style={{}}
                            type="number"
                            label="Purchasable line items"
                            helpText="Confidence threshold is 10 line items."
                            value={purchasableValue?.toString()}
                            onHeInput={(e: any) => setPurchasableValue(e.target.value)} />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={purchasableValue === purchasableValuePill || purchasableValue === ''} onClick={(e: any) => {setPurchasableValuePill(purchasableValue);setFilterDropdownStates({...filterDropdownStates, purchasable: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, purchasable: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => {setPurchasableValue(null);setPurchasableValuePill(null)}} />
                        </ButtonGroup> : null
                )}
                {(
                    futureDiscountValuePill > 0 ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['futureDiscount']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, futureDiscount: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, futureDiscount: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>Future discount line items: <span style={{fontWeight: 600}}>{futureDiscountValuePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField
                            required={true}
                            style={{}}
                            type="number"
                            label="Future discount line items"
                            helpText="Confidence threshold is 140 line items."
                            value={futureDiscountValue?.toString()}
                            onHeInput={(e: any) => setFutureDiscountValue(e.target.value)} />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={futureDiscountValue === futureDiscountValuePill || futureDiscountValue === ''} onClick={(e: any) => {setFutureDiscountValuePill(futureDiscountValue);setFilterDropdownStates({...filterDropdownStates, futureDiscount: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, futureDiscount: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => {setFutureDiscountValue(null);setFutureDiscountValuePill(null)}} />
                        </ButtonGroup> : null
                )}
                {(
                    newCustomTermValuePill > 0 ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['newCustomTerm']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, newCustomTerm: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, newCustomTerm: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>New custom terms: <span style={{fontWeight: 600}}>{newCustomTermValuePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField
                            required={true}
                            style={{}}
                            type="number"
                            label="New custom terms"
                            helpText="Confidence threshold is 10 line items."
                            value={newCustomTermValue?.toString()}
                            onHeInput={(e: any) => setNewCustomTermValue(e.target.value)} />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={newCustomTermValue === newCustomTermValuePill || newCustomTermValue === ''} onClick={(e: any) => {setNewCustomTermValuePill(newCustomTermValue);setFilterDropdownStates({...filterDropdownStates, newCustomTerm: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, newCustomTerm: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => {setNewCustomTermValue(null);setNewCustomTermValuePill(null)}} />
                        </ButtonGroup> : null
                )}
                {scenarioValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['scenario']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, scenario: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, scenario: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={scenarioValuesAsArrayPill}>
                    Scenario:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={scenarioValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleScenarioCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "Scenario")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(scenarioValuesAsArray?.length === scenarioValuesAsArrayPill?.length && scenarioValuesAsArrayPill?.filter((el: any) => scenarioValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || scenarioValuesAsArray?.length === 0}
                        onClick={() => {setScenarioValuesAsArrayPill(scenarioValuesAsArray);setFilterDropdownStates({...filterDropdownStates, scenario: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, scenario: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setScenarioValuesAsArray(null);setScenarioValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                {productTypeValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['productType']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, productType: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, productType: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={productTypeValuesAsArrayPill}>
                    Product type:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={productTypeValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleProductTypeCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "ProductType")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(productTypeValuesAsArray?.length === productTypeValuesAsArrayPill?.length && productTypeValuesAsArrayPill?.filter((el: any) => productTypeValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || productTypeValuesAsArray?.length === 0}
                        onClick={() => {setProductTypeValuesAsArrayPill(productTypeValuesAsArray);setFilterDropdownStates({...filterDropdownStates, productType: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, productType: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setProductTypeValuesAsArray(null);setProductTypeValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                {acceptanceModeValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['acceptanceMode']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, acceptanceMode: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, acceptanceMode: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={acceptanceModeValuesAsArrayPill}>
                    Acceptance mode:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={acceptanceModeValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleAcceptanceModeCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "AcceptanceMode")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(acceptanceModeValuesAsArray?.length === acceptanceModeValuesAsArrayPill?.length && acceptanceModeValuesAsArrayPill?.filter((el: any) => acceptanceModeValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || acceptanceModeValuesAsArray?.length === 0}
                        onClick={() => {setAcceptanceModeValuesAsArrayPill(acceptanceModeValuesAsArray);setFilterDropdownStates({...filterDropdownStates, acceptanceMode: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, acceptanceMode: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setAcceptanceModeValuesAsArray(null);setAcceptanceModeValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                {(
                    futureTransactionDateValuePill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['futureTransactionDate']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, futureTransactionDate: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, futureTransactionDate: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>Time travel:
                        <span style={{fontWeight: 600}}> {futureTransactionDateValuePill?.length > 0 ?  handleDateFormatShow() : ""}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <TextField type="date" value={futureTransactionDateValue} onHeInput={(e: any) => setFutureTransactionDateValue(e.target.value)} label="Future transaction date" />
                            <div style={styles.dropdownButtons}>
                            <Button disabled={futureTransactionDateValue === futureTransactionDateValuePill || futureTransactionDateValue === ''} onClick={(e: any) => {setFutureTransactionDateValuePill(futureTransactionDateValue);setFilterDropdownStates({...filterDropdownStates, futureTransactionDate: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, futureTransactionDate: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => { setFutureTransactionDateValue(null);setFutureTransactionDateValuePill(null)}} />
                        </ButtonGroup> : null
                )}
                {creationSourceValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['creationSource']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, creationSource: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, creationSource: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={creationSourceValuesAsArrayPill}>
                    Creation source:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={creationSourceValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleCreationSourceCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "CreationSource")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button disabled={(creationSourceValuesAsArray?.length === creationSourceValuesAsArrayPill?.length && creationSourceValuesAsArrayPill?.filter((el: any) => creationSourceValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || creationSourceValuesAsArray?.length === 0}
                        onClick={() => {setCreationSourceValuesAsArrayPill(creationSourceValuesAsArray);setFilterDropdownStates({...filterDropdownStates, creationSource: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, creationSource: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => {setCreationSourceValuesAsArray(null);setCreationSourceValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                {(
                    trafficSourcePill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['trafficSource']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, trafficSource: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, trafficSource: true})}>
                        <FilterPill slot="trigger" style={{marginLeft: 5}}>Traffic source: <span style={{fontWeight: 600}}>{trafficSourcePill}</span>
                        </FilterPill>
                        <div style={styles.dropdownContents}>
                        <RadioGroup
                            label="Source"
                            value={trafficSource}
                            onHeChange={(e: any) => setTrafficSource(e.target.value)}>
                            <Radio value="Live">Live</Radio>
                            <Radio value="Test">Test</Radio>
                        </RadioGroup>
                            <div style={styles.dropdownButtons}>
                            <Button disabled={trafficSourcePill === trafficSource} onClick={(e: any) => {setTrafficSourcePill(trafficSource);setFilterDropdownStates({...filterDropdownStates, trafficSource: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, trafficSource: false})}>Cancel</Button>
                            </div>
                        </div>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => { setTrafficSource(null); setTrafficSourcePill(null);}} />
                        </ButtonGroup> : null
                )}
                {transactionTypeValuesAsArrayPill !== null ? <ButtonGroup style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['transactionType']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, transactionType: false}, true)} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, transactionType: true})} style={{marginLeft: 5}} fixedPlacement>
                    <FilterPill slot="trigger" values={transactionTypeValuesAsArrayPill}>
                    Transaction type:
                    </FilterPill>
                    <Menu>
                    <div style={styles.dropdownContents}>
                        <CheckboxGroup
                        valueAsArray={transactionTypeValuesAsArray ?? []}
                        onHeCheckboxGroupChange={handleTransactionTypeCheckboxGroupChange}
                        >
                        {
                        ((availableFilters?.filter((el: any) => el.name === "TransactionType")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox key={el}>{el}</Checkbox>
                        })
                        }
                        </CheckboxGroup>

                        <div style={styles.dropdownButtons}>
                        <Button
                            disabled={(transactionTypeValuesAsArray?.length === transactionTypeValuesAsArrayPill?.length && transactionTypeValuesAsArrayPill?.filter((el: any) => transactionTypeValuesAsArray?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || transactionTypeValuesAsArray?.length === 0}
                            onClick={() => {setTransactionTypeValuesAsArrayPill(transactionTypeValuesAsArray);setFilterDropdownStates({...filterDropdownStates, transactionType: false})}} appearance="primary">
                            Apply
                        </Button>
                        <Button onClick={() => setFilterDropdownStates({...filterDropdownStates, transactionType: false})}>Cancel</Button>
                        </div>
                    </div>
                    </Menu>
                </Dropdown>
                <FilterPill clear onClick={(e: any) => { setTransactionTypeValuesAsArray(null);setTransactionTypeValuesAsArrayPill(null)}} />
                </ButtonGroup> : null}
                <Button onClick={() => clearFilters()} style={{marginLeft: 5}} appearance="stealth">
                    Clear all
                </Button>
            </>
        }
    }

    const clearFilters = () => {
        setStatusValuesAsArrayPill(['Active', 'Submitted', 'PurchaseInProgress']);
        setModifiedDate(new Date(new Date().setUTCHours(new Date().getUTCHours() - 720)).toISOString());
        setTotalDealValue2Pill(1000000);
        setIdValuePill(null);
        setIdTypePill("QuoteId");
        setExpirationDisplayPill("Select a value");
        setExpirationFilterValPill(null);
        setAssignedToValuesAsArrayPill(['Customer', 'Field']);
        setChannelValuesAsArrayPill(null);
        setDealTypeValuesAsArrayPill(null);
        setTotalLineItemsValuePill(null);
        setPriceableValuePill(null);
        setPurchasableValuePill(null);
        setFutureDiscountValuePill(null);
        setNewCustomTermValuePill(null);
        setScenarioValuesAsArrayPill(null);
        setProductTypeValuesAsArrayPill(null);
        setAcceptanceModeValuesAsArrayPill(null);
        setFutureTransactionDateValuePill(null);
        setCreationSourceValuesAsArrayPill(['Proposal Management Workspace']);
        setTrafficSourcePill('Live');
        setTransactionTypeValuesAsArrayPill(['UserPurchase']);

        setStatusValuesAsArray(['Active', 'Submitted', 'PurchaseInProgress']);
        setModifiedDate(new Date(new Date().setUTCHours(new Date().getUTCHours() - 720)).toISOString());
        setTotalDealValue2(1000000);
        setIdType("QuoteId");
        setExpirationDisplay("Select a value");
        setExpirationFilterVal(null);
        setAssignedToValuesAsArrayPill(['Customer', 'Field']);
        setChannelValuesAsArray(null);
        setDealTypeValuesAsArray(null);
        setTotalLineItemsValue(null);
        setPriceableValue(null);
        setPurchasableValue(null);
        setFutureDiscountValue(null);
        setNewCustomTermValue(null);
        setScenarioValuesAsArray(null);
        setProductTypeValuesAsArray(null);
        setAcceptanceModeValuesAsArray(null);
        setFutureTransactionDateValue(null);
        setCreationSourceValuesAsArray(['Proposal Management Workspace']);
        setTrafficSource('Live');
        setTransactionTypeValuesAsArray(['UserPurchase']);
    }

    const [currentController, setCurrentController] = useState(new AbortController());
    const handleFilterRequest = () => {
        if (loading) currentController.abort();

        const newController = new AbortController();
        setCurrentController(newController);
        const { signal } = newController;
        
        var filterStr = "";
        if (statusValuesAsArrayPill?.length > 0)
        {
            filterStr += `QuoteStatus in ('${statusValuesAsArrayPill.join("','")}') and `;
            searchParams.set('status', statusValuesAsArrayPill.join(","));   
        } else { searchParams.delete('status'); }
        if (totalDealValue2Pill !== null && totalDealValue2Pill >= 0)
        {
            filterStr += `ProposalValue gt ${totalDealValue2Pill} and `;
            searchParams.set('totalDealValue', totalDealValue2Pill);
        } else { searchParams.delete('totalDealValue'); }
        if (idValuePill !== null && idValuePill?.length > 0)
        {
            if (idValuePill.includes(','))
            {
                let idValues = idValuePill.split(',');
                filterStr += `${idTypePill} in ('${idValues.join("','")}') and `;
            } else {
                filterStr += `${idTypePill} eq '${idValuePill}' and `;
            }

            searchParams.set('idType', idTypePill);
            searchParams.set('idValue', idValuePill);
        } else { searchParams.delete('idValue'); searchParams.delete('idType'); }
        if (expirationFilterValPill !== null && expirationFilterValPill?.length > 0)
        {
            filterStr += `QuoteExpireIn eq '${expirationFilterValPill}' and `;
            searchParams.set('expirationFilterVal', expirationFilterValPill);
            searchParams.set('expirationDisplay', expirationDisplayPill);
        } else { searchParams.delete('expirationFilterVal'); searchParams.delete('expirationDisplay'); }
        if (assignedToValuesAsArrayPill?.length > 0)
        {
            filterStr += `AssignedTo in ('${assignedToValuesAsArrayPill.join("','")}') and `;
            searchParams.set('assignedTo', assignedToValuesAsArrayPill.join(","));
        } else { searchParams.delete('assignedTo'); }
        if (channelValuesAsArrayPill?.length > 0)
        {
            filterStr += `Channel in ('${channelValuesAsArrayPill.join("','")}') and `;
            searchParams.set('channel', channelValuesAsArrayPill.join(","));
        } else { searchParams.delete('channel'); }
        if (dealTypeValuesAsArrayPill?.length > 0)
        {
            filterStr += `DealType in ('${dealTypeValuesAsArrayPill.join("','")}') and `;
            searchParams.set('dealType', dealTypeValuesAsArrayPill.join(","));
        } else { searchParams.delete('dealType'); }
        if (totalLineItemsValuePill !== null && totalLineItemsValuePill > 0)
        {
            filterStr += `LineItemCount ge ${totalLineItemsValuePill} and `;
            searchParams.set('totalLineItems', totalLineItemsValuePill);
        } else { searchParams.delete('totalLineItems'); }
        if (priceableValuePill !== null && priceableValuePill > 0)
        {
            filterStr += `PriceableCount ge ${priceableValuePill} and `;
            searchParams.set('priceable', priceableValuePill);
        } else { searchParams.delete('priceable'); }
        if (purchasableValuePill !== null && purchasableValuePill > 0)
        {
            filterStr += `PurchaseableCount ge ${purchasableValuePill} and `;
            searchParams.set('purchasable', purchasableValuePill);
        } else { searchParams.delete('purchasable'); }
        if (futureDiscountValuePill !== null && futureDiscountValuePill > 0)
        {
            filterStr += `FutureDiscountCount ge ${futureDiscountValuePill} and `;
            searchParams.set('futureDiscount', futureDiscountValuePill);
        } else { searchParams.delete('futureDiscount'); }
        if (newCustomTermValuePill !== null && newCustomTermValuePill > 0)
        {
            filterStr += `NewCustomTermCount ge ${newCustomTermValuePill} and `;
            searchParams.set('newCustomTerm', newCustomTermValuePill);
        } else { searchParams.delete('newCustomTerm'); }
        if (scenarioValuesAsArrayPill?.length > 0)
        {
            filterStr += `Scenario in ('${scenarioValuesAsArrayPill.join("','")}') and `;
            searchParams.set('scenario', scenarioValuesAsArrayPill.join(","));
        } else { searchParams.delete('scenario'); }
        if (productTypeValuesAsArrayPill?.length > 0)
        {
            filterStr += `ProductType in ('${productTypeValuesAsArrayPill.join("','")}') and `;
            searchParams.set('productType', productTypeValuesAsArrayPill.join(","));
        } else { searchParams.delete('productType'); }
        if (acceptanceModeValuesAsArrayPill?.length > 0)
        {
            filterStr += `AcceptanceMode in ('${acceptanceModeValuesAsArrayPill.join("','")}') and `;
            searchParams.set('acceptanceMode', acceptanceModeValuesAsArrayPill.join(","));
        } else { searchParams.delete('acceptanceMode'); }
        if (futureTransactionDateValuePill !== null && futureTransactionDateValuePill?.length > 0)
        {
            let dateSplit = futureTransactionDateValue.split('-');
            let transDate = new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2])).toISOString();
            filterStr += `ValidateDate eq ${transDate} and `;
            searchParams.set('timeTravel', `${futureTransactionDateValue}`);
        } else { searchParams.delete('timeTravel'); }
        if (creationSourceValuesAsArrayPill?.length > 0)
        {
            filterStr += `CreationSource in ('${creationSourceValuesAsArrayPill.map((el: any) => creationSourceValMapping[el]).join("','")}') and `;
            searchParams.set('creationSource', creationSourceValuesAsArrayPill.join(","));
        } else { searchParams.delete('creationSource'); }
        if (trafficSourcePill?.length > 0)
        {
            filterStr += `TrafficType eq '${trafficSourcePill}' and `;
            searchParams.set('trafficSource', trafficSourcePill);
        } else { searchParams.delete('trafficSource'); }
        if (transactionTypeValuesAsArrayPill?.length > 0)
        {
            filterStr += `TransactionType in ('${transactionTypeValuesAsArrayPill.join("','")}') and `;
            searchParams.set('transactionType', transactionTypeValuesAsArrayPill.join(","));
        } else { searchParams.delete('transactionType'); }
        
        navigate({ ...location, search: searchParams.toString() });
        if (!anyActivePills)
            return;

        filterStr += `ModifiedDate gt '${modifiedDate}'`;

        var defaultBody = {
            "filter": filterStr,
            "top": 75,
            "select": "QuoteName,QuoteId,Status,NeedAttention,ExpirationDate,ModifiedDate,AssignedTo,IsCAPT,EstimatedDealValue,TotalPrice,MACCinUSD,BillingCurrency,AgreementStatus,AgreementId,AgreementAcceptanceMode,LineItemsCount,PurchaseableCount,PriceableCount,FutureDiscountCount,NewCustomTermCount,MSContact,SoldToAccountId,SoldToOrganizationId,BillToAccountId,BillToOrganizationId,BillingGroupId,EndCustomerAccountId,EndCustomerOrganizationId,TotalPriceInListPrice,TotalPriceInRetailPrice,TotalPriceInDiscountedRetailPrice,ApprovalStatus,ApprovalWorkflow,TransactionType,TransactionModel,Market,OpportunityId,VLAgreementNumber,CreatedDate,SubmittedDate,LastPricedDate,MonthlyCreditLimit,ExchangeRateDate,ScenarioName,CreatedAppSourceId"
        }

        var listUrl = `/v1/proposal/list`;
        quoteTimelineService.sendCall(listUrl, quoteTimelineService.generateCallOptions(accessToken, JSON.stringify(defaultBody), "POST", undefined, signal), (param: any) => setData({...data, rows: param}));
    };

    useEffect(() => {
        // This code runs after the component mounts
      
        return () => {
            // This code runs before the component unmounts
            currentController.abort();
            clearInterval(currentRefreshIntervalId);
        };
      }, [currentController,currentRefreshIntervalId]); 

    const handleFilter = () => {
        if (!anyActiveFilters) return;
        
        if (statusValuesAsArray?.length > 0)
        {
            setStatusValuesAsArrayPill(statusValuesAsArray);    
        } else { setStatusValuesAsArrayPill(null) }
        if (totalDealValue2 !== null && totalDealValue2 !== '' && totalDealValue2 >= 0)
        {
            setTotalDealValue2Pill(totalDealValue2);
        } else { setTotalDealValue2Pill(null) }
        if (idValue !== null && idValue?.length > 0)
        {
            setIdValuePill(idValue);
            setIdTypePill(idType);
        } else { setIdValuePill(null);setIdTypePill('QuoteId');setIdType('QuoteId') }
        if (expirationFilterVal !== null && expirationFilterVal?.length > 0)
        {
            setExpirationFilterValPill(expirationFilterVal);
            setExpirationDisplayPill(expirationDisplay);
        } else { setExpirationFilterValPill(null);setExpirationDisplayPill('Select a value'); }
        if (assignedToValuesAsArray?.length > 0)
        {
            setAssignedToValuesAsArrayPill(assignedToValuesAsArray);
        } else { setAssignedToValuesAsArrayPill(null) }
        if (channelValuesAsArray?.length > 0)
        {
            setChannelValuesAsArrayPill(channelValuesAsArray);
        } else { setChannelValuesAsArrayPill(null) }
        if (dealTypeValuesAsArray?.length > 0)
        {
            setDealTypeValuesAsArrayPill(dealTypeValuesAsArray);
        } else { setDealTypeValuesAsArrayPill(null) }
        if (totalLineItemsValue !== null && totalLineItemsValue > 0)
        {
            setTotalLineItemsValuePill(totalLineItemsValue);
        } else { setTotalLineItemsValuePill(null) }
        if (priceableValue !== null && priceableValue > 0)
        {
            setPriceableValuePill(priceableValue);
        } else { setPriceableValuePill(null) }
        if (purchasableValue !== null && purchasableValue > 0)
        {
            setPurchasableValuePill(purchasableValue);
        } else { setPurchasableValuePill(null) }
        if (futureDiscountValue !== null && futureDiscountValue > 0)
        {
            setFutureDiscountValuePill(futureDiscountValue);
        } else { setFutureDiscountValuePill(null) }
        if (newCustomTermValue !== null && newCustomTermValue > 0)
        {
            setNewCustomTermValuePill(newCustomTermValue);
        } else { setNewCustomTermValuePill(null) }
        if (scenarioValuesAsArray?.length > 0)
        {
            setScenarioValuesAsArrayPill(scenarioValuesAsArray);
        } else { setScenarioValuesAsArrayPill(null) }
        if (productTypeValuesAsArray?.length > 0)
        {
            setProductTypeValuesAsArrayPill(productTypeValuesAsArray);
        } else { setProductTypeValuesAsArrayPill(null) }
        if (acceptanceModeValuesAsArray?.length > 0)
        {
            setAcceptanceModeValuesAsArrayPill(acceptanceModeValuesAsArray);
        } else { setAcceptanceModeValuesAsArrayPill(null) }
        if (futureTransactionDateValue !== null && futureTransactionDateValue?.length > 0)
        {
            setFutureTransactionDateValuePill(futureTransactionDateValue);
        } else { setFutureTransactionDateValuePill(null) }
        if (creationSourceValuesAsArray?.length > 0)
        {
            setCreationSourceValuesAsArrayPill(creationSourceValuesAsArray);
        } else { setCreationSourceValuesAsArrayPill(null) }
        if (trafficSource?.length > 0)
        {
            setTrafficSourcePill(trafficSource);
        } else { setTrafficSourcePill(null) }
        if (transactionTypeValuesAsArray?.length > 0)
        {
            setTransactionTypeValuesAsArrayPill(transactionTypeValuesAsArray);
        } else { setTransactionTypeValuesAsArrayPill(null) }
        
        navigate({ ...location, search: searchParams.toString() });
        setFilterPanelOpen(false);
    };

    const reApplyPillsToTemps = () => {
        setStatusValuesAsArray(statusValuesAsArrayPill);
        setTotalDealValue2(totalDealValue2Pill);
        setIdValue(idValuePill);
        setIdType(idTypePill);
        setExpirationFilterVal(expirationFilterValPill);
        setExpirationDisplay(expirationDisplayPill);
        setAssignedToValuesAsArray(assignedToValuesAsArrayPill);
        setChannelValuesAsArray(channelValuesAsArrayPill);
        setDealTypeValuesAsArray(dealTypeValuesAsArrayPill);
        setTotalLineItemsValue(totalLineItemsValuePill);
        setPriceableValue(priceableValuePill);
        setPurchasableValue(purchasableValuePill);
        setFutureDiscountValue(futureDiscountValuePill);
        setNewCustomTermValue(newCustomTermValuePill);
        setScenarioValuesAsArray(scenarioValuesAsArrayPill);
        setProductTypeValuesAsArray(productTypeValuesAsArrayPill);
        setAcceptanceModeValuesAsArray(acceptanceModeValuesAsArrayPill);
        setFutureTransactionDateValue(futureTransactionDateValuePill);
        setCreationSourceValuesAsArray(creationSourceValuesAsArrayPill);
        setTrafficSource(trafficSourcePill);
        setTransactionTypeValuesAsArray(transactionTypeValuesAsArrayPill);
    }

    const handleDateDropdown = (e: any, minusHours: any) => {
        let newDate = new Date(new Date().setUTCHours(new Date().getUTCHours() - minusHours)).toISOString();
        setModifiedDate(newDate);
        setModifiedDateDisplay(e.target.textContent);
    }

    useEffect(() => {
        setAnyActiveFilters(
            statusValuesAsArray?.length > 0 ||
            (totalDealValue2 !== null && totalDealValue2 >= 0) || 
            idValue?.length > 0 || 
            expirationFilterVal?.length > 0 || 
            assignedToValuesAsArray?.length > 0 || 
            channelValuesAsArray?.length > 0 ||
            dealTypeValuesAsArray?.length > 0 ||
            (totalLineItemsValue !== null && totalLineItemsValue >= 0) ||
            (priceableValue !== null && priceableValue >= 0) ||
            (purchasableValue !== null && purchasableValue >= 0) ||
            (futureDiscountValue !== null && futureDiscountValue >= 0) ||
            (newCustomTermValue !== null && newCustomTermValue >= 0) ||
            scenarioValuesAsArray?.length > 0 ||
            productTypeValuesAsArray?.length > 0 ||
            acceptanceModeValuesAsArray?.length > 0 ||
            (futureTransactionDateValue !== null && futureTransactionDateValue?.length > 0) ||
            creationSourceValuesAsArray?.length > 0 ||
            trafficSource?.length > 0 ||
            transactionTypeValuesAsArray?.length > 0
        );
    }, [statusValuesAsArray,
        totalDealValue2,
        idValue,
        expirationFilterVal,
        assignedToValuesAsArray,
        channelValuesAsArray,
        dealTypeValuesAsArray,
        totalLineItemsValue,
        priceableValue,
        purchasableValue,
        futureDiscountValue,
        newCustomTermValue,
        scenarioValuesAsArray,
        productTypeValuesAsArray,
        acceptanceModeValuesAsArray,
        futureTransactionDateValue,
        creationSourceValuesAsArray,
        trafficSource,
        transactionTypeValuesAsArray]);

    const [filterBuildLoad, setFilterBuildLoad] = React.useState(false);
    useEffect(() => {
        setAnyActivePills(
            statusValuesAsArrayPill?.length > 0 ||
            (totalDealValue2Pill !== null && parseInt(totalDealValue2Pill) >= 0) || 
            idValuePill?.length > 0 || 
            expirationFilterValPill?.length > 0 || 
            assignedToValuesAsArrayPill?.length > 0 || 
            channelValuesAsArrayPill?.length > 0 ||
            dealTypeValuesAsArrayPill?.length > 0 ||
            (totalLineItemsValuePill !== null && totalLineItemsValuePill >= 0) ||
            (priceableValuePill !== null && priceableValuePill >= 0) ||
            (purchasableValuePill !== null && purchasableValuePill >= 0) ||
            (futureDiscountValuePill !== null && futureDiscountValuePill >= 0) ||
            (newCustomTermValuePill !== null && newCustomTermValuePill >= 0) ||
            scenarioValuesAsArrayPill?.length > 0 ||
            productTypeValuesAsArrayPill?.length > 0 ||
            acceptanceModeValuesAsArrayPill?.length > 0 ||
            (futureTransactionDateValuePill !== null && futureTransactionDateValuePill?.length > 0) ||
            creationSourceValuesAsArrayPill?.length > 0 ||
            trafficSourcePill?.length > 0 ||
            transactionTypeValuesAsArrayPill?.length > 0
        );
        setFilterBuildLoad(!filterBuildLoad);
    }, [statusValuesAsArrayPill,
        totalDealValue2Pill,
        idValuePill,
        expirationFilterValPill,
        assignedToValuesAsArrayPill,
        channelValuesAsArrayPill,
        dealTypeValuesAsArrayPill,
        totalLineItemsValuePill,
        priceableValuePill,
        purchasableValuePill,
        futureDiscountValuePill,
        newCustomTermValuePill,
        scenarioValuesAsArrayPill,
        productTypeValuesAsArrayPill,
        acceptanceModeValuesAsArrayPill,
        futureTransactionDateValuePill,
        creationSourceValuesAsArrayPill,
        trafficSourcePill,
        transactionTypeValuesAsArrayPill,
        modifiedDate]);

    useEffect(() => {
        if (accessToken != null)
        {
            handleFilterRequest();
            handleRefreshDropdown(null, 3, "3 minutes")
        }
    }, [filterBuildLoad, accessToken]);

    const handleFilterRequestRef = useRef(handleFilterRequest);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpen2, setIsOpen2] = React.useState(false);
    const [shareLoading, setShareLoading] = React.useState(false);

    useEffect(() => {
        handleFilterRequestRef.current = handleFilterRequest;
    }, [handleFilterRequest]);

    const handleRefreshDropdown = (e: any, val: number, display: string) => {
        if (!anyActivePills)
        {
            if (currentRefreshIntervalId)
                clearInterval(currentRefreshIntervalId);
                setCurrentRefreshIntervalId(null);
                
            return;
        }

        if (currentRefreshIntervalId)
            clearInterval(currentRefreshIntervalId);
            setCurrentRefreshIntervalId(null);

        if (val === 0)
        {
            setRefreshValDisplay(display);
            return;
        }

        var intervalId = setInterval(() => {
            handleFilterRequestRef.current();
        }, val * 60 * 1000);

        setCurrentRefreshIntervalId(intervalId);
        setRefreshValDisplay(display);
    }

    const handleExpirationDropdown = (e: any, val: string) => {
        setExpirationFilterVal(val);
        setExpirationDisplay(e.target.innerHTML);
    }

    const handleShareQueryClick = async (e: any) => {
        setIsOpen(true);
        setShareLoading(true);
        const url = window.location.href;
        await navigator.clipboard.writeText(url);
        setShareLoading(false);
    }

    const exportToCSV = () => {
        if (data?.rows?.length === 0 || data?.rows === undefined) {
            setIsOpen2(true);
            return;
        }

        const csvData = data?.rows?.map((row: any) => ({
            ...row,
            EstimatedDealValue: getRealTextValForMonetaryVal(row.EstimatedDealValue?.value ?? '-'),
            TotalPrice: getRealTextValForMonetaryVal(row.TotalPrice?.value ?? '-'),
            MACCinUSD: getRealTextValForMonetaryVal(row.MACCinUSD?.value ?? '-'),
            TotalPriceInListPrice: getRealTextValForMonetaryVal(row.TotalPriceInListPrice?.value ?? '-'),
            TotalPriceInRetailPrice: getRealTextValForMonetaryVal(row.TotalPriceInRetailPrice?.value ?? '-'),
            TotalPriceInDiscountedRetailPrice: getRealTextValForMonetaryVal(row.TotalPriceInDiscountedRetailPrice?.value ?? '-'),
            MonthlyCreditLimit: getRealTextValForMonetaryVal(row.MonthlyCreditLimit?.value ?? '-'),
        }));

        const csvRows = [];
        const headers = Object.keys(csvData[0]);
        csvRows.push(headers.join(','));

        for (const row of csvData) {
            const values = headers.map(header => JSON.stringify(row[header], (key, value) => value === null ? '' : value));
            csvRows.push(values.join(','));
        }

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Proposal_List.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const tableCss = `
        .row-style::part(base) {
            background: red;
        }
    `
    const statusCss = `
        .yellow-badge::part(base) {
            background: #FFF4CE;
            color: #343a40;
            width: 100%;
        }

        .green-badge::part(base) {
            background: #DFF6DD;
            color: #198754;
            border-radius: 5px;
        }

        .blue-badge::part(base) {
            background: #EFF6FC;
            color: #0d6efd;
            border-radius: 5px;
        }

        .grey-badge::part(base) {
            background: #dee2e6;
            color: #343a40;
        }

        .red-badge::part(base) {
            background: #FDE7E9;
            color: #dc3545;
        }
        `;
    
    const mapStatusClass = (status: string) => {
        switch (status) {
            case "Completed":
            case "Active":
                return "green";
            case "Draft":
                return "yellow";
            case "Submitted":
            case "PurchaseInProgress":
                return "blue";
            case "Error":
                return "red";
            case "Expired":
            case "Rejected":
                return "red";
            default:
                return "grey";
        }
    }

    const mapStatusIcon = (status: string) => {
        switch (status) {
            case "Active":
                return "completed";
            case "Draft":
                return "openenrollment";
            case "Submitted":
                return "clock";
            case "Completed":
                return "documentapproval";
            case "PurchaseInProgress":
                return "silver";
            case "Error":
                return "errorbadge";
            case "Expired":
                return "hourglass";
            case "Rejected":
                return "buttonx";
            default:
                return "document";
        }
    }
    
    return (<div>
        <PageTitle key="1">
            View all proposals
        </PageTitle>
        <CommandBar key="2" style={{marginTop: 40}}>
        <Button appearance="command" onClick={() => setFilterPanelOpen(true)}>
            <Icon slot="start" name="filter"></Icon>
            Filter
        </Button>
        <Button onClick={handleShareQueryClick} appearance="command">
            <Icon slot="start" name="share"></Icon>
            Share query
        </Button>
        <Button onClick={exportToCSV} appearance="command">
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={process.env.PUBLIC_URL + '/ef73.svg'} style={{marginRight: 5, width: '16px', height: '16px', color: '#198754' }} alt="document with a info icon" />
                Export Excel
            </div>
        </Button>
        <Dropdown fixedPlacement slot="action-end" key="timeDropdown">
            <Button appearance="command" slot="trigger" caret>
                <Icon name="clock" slot="start"></Icon>
                {modifiedDateDisplay}
            </Button>
            <Menu>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 1)}>Last hour</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 6)}>Last 6 hours</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 12)}>Last 12 hours</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 24)}>Last 24 hours</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 48)}>Last 2 days</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 336)}>Last 2 weeks</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 720)}>Last 30 days</MenuItem>
                <MenuItem onHeChange={(e: any) => handleDateDropdown(e, 1480)}>Last 60 days</MenuItem>
            </Menu>
        </Dropdown>
        <Dropdown fixedPlacement slot="action-end">
            <Button appearance="command" slot="trigger" caret>
                <Icon name="repeatall" slot="start"></Icon>
                {refreshValDisplay === "off" ? "Automatic refreshing is off" : `Refreshes every ${refreshValDisplay}`}
            </Button>
                <Menu>
                <MenuItem onHeChange={(e: any) => handleRefreshDropdown(e, 0, "off")}>Off</MenuItem>
                <MenuItem onHeChange={(e: any) => handleRefreshDropdown(e, 3, "3 minutes")}>Every 3 minutes</MenuItem>
                <MenuItem onHeChange={(e: any) => handleRefreshDropdown(e, 10, "10 minutes")}>Every 10 minutes</MenuItem>
                <MenuItem onHeChange={(e: any) => handleRefreshDropdown(e, 30, "30 minutes")}>Every 30 minutes</MenuItem>
                <MenuItem onHeChange={(e: any) => handleRefreshDropdown(e, 60, "1 hour")}>Every 1 hour</MenuItem>
                </Menu>
            </Dropdown>
        </CommandBar>
        <div style={{margin: 5}}>
            <text style={{fontSize: '15px', fontWeight: 600}}>Filtered by:</text>
            {!anyActivePills ? <text style={{marginLeft: 10, fontSize: '15px', fontWeight: 500}}>No active filters.</text> : generateActiveFiltersPills()}
        </div>
        
        {loading ? <Progress key="3" label="Progress" indeterminate /> : null}
        <style key="4">
            {statusCss}
            {tableCss}
        </style>
        <DataGrid key="5" style={{marginBottom: 50}} onHeSort={handleSortNeedAttention} fixedColumn fixedHeading rows={pagedRows?.map((row: any) => ({id: row?.QuoteId, cells: {...row}}))} columns={data.columns} label="Proposals">
                {data?.rows?.map((row: any) => {
                    let tracking = myTrackerList?.find((el: any) => el.quoteId === row?.QuoteId);
                    return (<>
                    <div
                        slot={`QuoteName-${row?.QuoteId}`}
                        key={`QuoteName-${row?.QuoteId}`}>
                        <Link target="_blank" to={futureTransactionDateValuePill != null ? `/proposal-details/${row?.QuoteId}?validateDate=${futureTransactionDateValuePill}` : `/proposal-details/${row?.QuoteId}`}>{row.QuoteName}</Link>
                    </div>
                    <div
                        slot={`NeedAttention-${row?.QuoteId}`}
                        key={`NeedAttention-${row?.QuoteId}`}>
                        {row.NeedAttention == "Yes" ? 
                        <span style={{fontWeight: 500, color: '#A80000', display: 'flex', alignItems: 'center' }}>
                            <span>Yes</span><Icon name="warning" style={{color: '#A80000', marginLeft: 5, marginBottom: 2}} />
                        </span> : row.NeedAttention == "Maybe" ? <span style={{fontWeight: 500}}>{row?.NeedAttention}</span> : <span style={{fontWeight: 400}}>{row?.NeedAttention}</span>}
                    </div>
                    <div
                    className="row-style"
                    slot={`Status-${row?.QuoteId}`}
                    key={`Status-${row?.QuoteId}`}
                      >
                    <Badge className={`${mapStatusClass(row?.Status)}-badge`}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        {row?.Status === 'Completed' || row?.Status === 'PurchaseInProgress' ? <img src={process.env.PUBLIC_URL + row?.Status === 'PurchaseInProgress' ? '/eae4.svg' : '/f28b.svg'} style={{marginRight: 5, width: '12px', height: '12px', color: '#198754' }} alt="document with a info icon" />
                        : <Icon name={mapStatusIcon(row?.Status)} style={{marginRight: 5, fontSize: 12}}/>}
                        <span>{row?.Status}</span>
                        </div>
                    </Badge>
                  </div>
                  <div
                        slot={`EstimatedDealValue-${row?.QuoteId}`}
                        key={`EstimatedDealValue-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.EstimatedDealValue?.value ?? '-')}
                    </div>
                    <div
                        slot={`MACCinUSD-${row?.QuoteId}`}
                        key={`MACCinUSD-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.MACCinUSD?.value  ?? '-')}
                    </div>
                    <div
                        slot={`TotalPrice-${row?.QuoteId}`}
                        key={`TotalPrice-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.TotalPrice?.value ?? '-')}
                    </div>
                    <div
                        slot={`PurchaseableCount-${row?.QuoteId}`}
                        key={`PurchaseableCount-${row?.QuoteId}`}>
                        {JSON.stringify(row?.PurchaseableCount) === '{}' ? 0 : row?.PurchaseableCount}
                    </div>
                    <div
                        slot={`LineItemsCount-${row?.QuoteId}`}
                        key={`LineItemsCount-${row?.QuoteId}`}>
                        {JSON.stringify(row?.LineItemsCount) === '{}' ? 0 : row?.LineItemsCount}
                    </div>
                    <div
                        slot={`PriceableCount-${row?.QuoteId}`}
                        key={`PriceableCount-${row?.QuoteId}`}>
                        {JSON.stringify(row?.PriceableCount) === '{}' ? 0 : row?.PriceableCount}
                    </div>
                    <div
                        slot={`FutureDiscountCount-${row?.QuoteId}`}
                        key={`FutureDiscountCount-${row?.QuoteId}`}>
                        {JSON.stringify(row?.FutureDiscountCount) === '{}' ? 0 : row?.FutureDiscountCount}
                    </div>
                    <div
                        slot={`NewCustomTermCount-${row?.QuoteId}`}
                        key={`NewCustomTermCount-${row?.QuoteId}`}>
                        {JSON.stringify(row?.NewCustomTermCount) === '{}' ? 0 : row?.NewCustomTermCount}
                    </div>
                    <div
                        slot={`TotalPriceInListPrice-${row?.QuoteId}`}
                        key={`TotalPriceInListPrice-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.TotalPriceInListPrice?.value ?? '-')}
                    </div>
                    <div
                        slot={`TotalPriceInRetailPrice-${row?.QuoteId}`}
                        key={`TotalPriceInRetailPrice-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.TotalPriceInRetailPrice?.value ?? '-')}
                    </div>
                    <div
                        slot={`TotalPriceInDiscountedRetailPrice-${row?.QuoteId}`}
                        key={`TotalPriceInDiscountedRetailPrice-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.TotalPriceInDiscountedRetailPrice?.value ?? '-')}
                    </div>
                    <div
                        slot={`MonthlyCreditLimit-${row?.QuoteId}`}
                        key={`MonthlyCreditLimit-${row?.QuoteId}`}>
                        {getTextValForMonetaryVal(row?.MonthlyCreditLimit?.value ?? '-')}
                    </div>
                    <div
                        slot={`AgreementStatus-${row?.QuoteId}`}
                        key={`AgreementStatus-${row?.QuoteId}`}>
                        {row?.AgreementStatus == "" ? '-' : row?.AgreementStatus}
                    </div>
                    <div
                        slot={`AgreementId-${row?.QuoteId}`}
                        key={`AgreementId-${row?.QuoteId}`}>
                        {row?.AgreementId == "" ? '-' : row?.AgreementId}
                    </div>
                    <div
                        slot={`AgreementAcceptanceMode-${row?.QuoteId}`}
                        key={`AgreementAcceptanceMode-${row?.QuoteId}`}>
                        {row?.AgreementAcceptanceMode == "" ? '-' : row?.AgreementAcceptanceMode}
                    </div>
                    <div
                        slot={`IsCAPT-${row?.QuoteId}`}
                        key={`IsCAPT-${row?.QuoteId}`}>
                        {row?.IsCAPT == null || row?.IsCAPT == "" ? 'Unknown' : row?.IsCAPT}
                    </div>
                    <div
                        slot={`MSContact-${row?.QuoteId}`}
                        key={`MSContact-${row?.QuoteId}`}>
                        {row?.MSContact == "" ? '-' : row?.MSContact}
                    </div>
                    <div
                        slot={`BillToAccountId-${row?.QuoteId}`}
                        key={`BillToAccountId-${row?.QuoteId}`}>
                        {row?.BillToAccountId == "" ? '-' : row?.BillToAccountId}
                    </div>
                    <div
                        slot={`BillToOrganizationId-${row?.QuoteId}`}
                        key={`BillToOrganizationId-${row?.QuoteId}`}>
                        {row?.BillToOrganizationId == "" ? '-' : row?.BillToOrganizationId}
                    </div>
                    <div
                        slot={`BillingGroupId-${row?.QuoteId}`}
                        key={`BillingGroupId-${row?.QuoteId}`}>
                        {row?.BillingGroupId == "" ? '-' : row?.BillingGroupId}
                    </div>
                    <div
                        slot={`SoldToAccountId-${row?.QuoteId}`}
                        key={`SoldToAccountId-${row?.QuoteId}`}>
                        {row?.SoldToAccountId == "" ? '-' : row?.SoldToAccountId}
                    </div>
                    <div
                        slot={`SoldToOrganizationId-${row?.QuoteId}`}
                        key={`SoldToOrganizationId-${row?.QuoteId}`}>
                        {row?.SoldToOrganizationId == "" ? '-' : row?.SoldToOrganizationId}
                    </div>
                    <div
                        slot={`EndCustomerAccountId-${row?.QuoteId}`}
                        key={`EndCustomerAccountId-${row?.QuoteId}`}>
                        {row?.EndCustomerAccountId == "" ? '-' : row?.EndCustomerAccountId}
                    </div>
                    <div
                        slot={`EndCustomerOrganizationId-${row?.QuoteId}`}
                        key={`EndCustomerOrganizationId-${row?.QuoteId}`}>
                        {row?.EndCustomerOrganizationId == "" ? '-' : row?.EndCustomerOrganizationId}
                    </div>
                    <div
                        slot={`ApprovalStatus-${row?.QuoteId}`}
                        key={`ApprovalStatus-${row?.QuoteId}`}>
                        {row?.ApprovalStatus == "" ? '-' : row.ApprovalStatus?.charAt(0)?.toUpperCase() + row.ApprovalStatus?.slice(1) ?? '-'}
                    </div>
                    <div
                        slot={`OpportunityId-${row?.QuoteId}`}
                        key={`OpportunityId-${row?.QuoteId}`}>
                        {row?.OpportunityId == "" ? '-' : row?.OpportunityId}
                    </div>
                    <div
                        slot={`VLAgreementNumber-${row?.QuoteId}`}
                        key={`VLAgreementNumber-${row?.QuoteId}`}>
                        {row?.VLAgreementNumber == null ? 'null' : row?.VLAgreementNumber?.length === 0 ? 'null' : row?.VLAgreementNumber[0]}
                    </div>
                    <div
                        slot={`ExchangeRateDate-${row?.QuoteId}`}
                        key={`ExchangeRateDate-${row?.QuoteId}`}>
                        {row?.ExchangeRateDate == "" ? '-' : row?.ExchangeRateDate}
                    </div>
                    <div
                        slot={`ScenarioName-${row?.QuoteId}`}
                        key={`ScenarioName-${row?.QuoteId}`}>
                        {row?.ScenarioName == "" ? '-' : row?.ScenarioName}
                    </div>
                    <div
                        slot={`icon-${row?.QuoteId}`}
                        key={`icon-${row?.QuoteId}`}
                    >
                     <Dropdown fixedPlacement>
                        <Button slot="trigger" appearance="command">
                            <Icon name="more" label="More" style={{ fontSize: '14px' }}></Icon>
                        </Button>
                        <Menu>
                            <MenuItem onClick={() => futureTransactionDateValuePill != null ? window.open(process.env.PUBLIC_URL + `/proposal-details/${row?.QuoteId}?validateDate=${futureTransactionDateValuePill}`, '_blank') : window.open(process.env.PUBLIC_URL + '/proposal-details/' + row?.QuoteId, '_blank')}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                <Icon name="view" style={{color: '#0d6efd', marginRight: 5}} />
                                View details
                                </div>
                            </MenuItem>
                            {
                                tracking ? <MenuItem onClick={() => RemoveQuoteFromTrackerList(row?.QuoteId)}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Icon name="delete" style={{color: '#0d6efd', marginRight: 5}} />
                                                Stop tracking
                                                </div>
                                            </MenuItem>
                                : <MenuItem onClick={() => AddNewQuoteToTrack(row?.QuoteId, () => null)}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={process.env.PUBLIC_URL + '/e664.svg'} style={{marginRight: 5, width: '14px', height: '14px', color: '#198754' }} alt="document with a info icon" />
                                    Track proposal
                                    </div>
                                </MenuItem>
                            }
                            <MenuItem onClick={() => window.open(row.IsTest ? `https://stable.c3.mepla.microsoft.com/quote/${row?.QuoteId}/general` : `https://proposals.microsoft.com/quote/${row?.QuoteId}/general`)}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={process.env.PUBLIC_URL + '/e8e5.svg'} style={{marginRight: 5, width: '14px', height: '14px', color: '#198754' }} alt="document with a info icon" />
                                Open in Proposal management
                                </div>
                            </MenuItem>
                        </Menu>
                    </Dropdown>   
                    </div>
                    </>
                )})}
                <Callout slot="Status-column-header" heading="Status" fixed-placement>
                    <Button slot="anchor" appearance="link">
                    <Icon name="info" label="More info" style={{ fontSize: '14px' }}></Icon>
                    </Button>
                    <p>
                    The status displayed here is a simpler depiction than what is provided in the Proposal Management Workspace which calculates a status to provide a more comprehensive view of the state of the proposal.
                    </p>
                </Callout>
                {data?.rows?.length > 0 ? <Pagination
                    slot="pagination"
                    value={pageNumber}
                    pageCount={pageCount}
                    onHePageChange={handlePageChange}
                /> : null}
                <div slot="no-records" style={{margin: 50}}>
                    <Icon name="clearfilter" style={{ fontSize: '20px', margin: '10px', padding: '18px', backgroundColor: 'rgba(206, 206, 206, 0.5)', borderRadius: '50%' }}/><br />
                    <span>No results found. Try adjusting your filter criteria.</span><br />
                    <Button disabled={!anyActiveFilters} onClick={() => clearFilters()} slot="footer" style={{marginBottom: '4vh', color: anyActiveFilters ? "#0d6efd" : "grey"}} appearance="stealth">
                        <u>Clear all</u>
                    </Button>
                </div>
        </DataGrid>

        <FlyInPanel key="6" heading="Filter" open={filterPanelOpen} onHeFlyInAfterHide={() => {setFilterPanelOpen(false);reApplyPillsToTemps()}}>
        
        <div style={{marginBottom: 5}}>
            <img src={process.env.PUBLIC_URL + '/infodoc.svg'} style={{ width: '18px', height: '18px' }} alt="document with a clock icon" />
            <text style={{margin: 5, fontWeight: 600}}>Foundational filters</text>
        </div>
        
        <Accordion>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Total value</span>
            Filter proposals, across the estimated deal value, MACC value in USD, or total price with at least the entered value.<br /><br />
            
            <div style={{fontSize: 14, fontWeight: 600}}>Value of proposal</div>
            <div style={{fontSize: 14}}>Custom values may be entered.</div>
            <Combobox
                autocomplete="inline"
                value={totalDealValue2 === null ? 0 : totalDealValue2}
                onHeInput={(e: any) => {
                    setTotalDealValue2(e.detail)}}
                onHeChange={(e: any) => {
                    setTotalDealValue2(e.target.value)}}
                style={{width: '100%', marginTop: 5}}
                placeholder="Select a value"
                freeform={true}
            >
                <Option value="0">0</Option>
                <Option value="100000">100,000</Option>
                <Option value="500000">500,000</Option>
                <Option value="1000000">1 million</Option>
                <Option value="50000000">50 million</Option>
                <Option value="100000000">100 million</Option>
                <Option value="250000000">250 million</Option>
                <Option value="500000000">500 million</Option>
                <Option value="1000000000">1 billion</Option>
            </Combobox>
        </AccordionItem>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Status</span>
            <CheckboxGroup valueAsArray={statusValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setStatusValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                {
                    ((availableFilters?.filter((el: any) => el.name === "QuoteStatus")[0] as any)?.possibleValues as any)?.map((el: any) => {
                        return <Checkbox 
                                key={el} 
                                label={el} 
                                
                            >
                                {el}
                            </Checkbox>
                    })
                }
            </CheckboxGroup>
        </AccordionItem>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Proposal or Opportunity ID(s)</span>
            <RadioGroup
                style={{marginBottom: 15}}
                label="Filter by"
                name="numbers"
                value={idType}
                onHeChange={(e: any) => setIdType(e.target.value)}
            >
                <Radio value="QuoteId">Proposal ID</Radio>
                <Radio value="OpportunityId">Opportunity ID</Radio>
            </RadioGroup>
            <TextField
                required={true}
                style={{}}
                label={idType === "QuoteId" ? "Proposal ID" : "Opportunity ID"}
                value={idValue}
                placeholder={`Enter a ${idType === "QuoteId" ? "proposal ID" : "opportunity ID"}`}
                onHeInput={(e: any) => setIdValue(e.target.value)} />
        </AccordionItem>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Expiration</span>
            Filter proposals, expiring within a time frame.
            
            <Dropdown style={{marginTop: 10, width: '100%'}}>
            <Button style={{textAlign: 'left', width: '100%'}} slot="trigger" caret>
                {expirationDisplay}
            </Button>
                <Menu>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '1hr')}>in 1 hour</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '6hr')}>in 6 hours</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '12hr')}>in 12 hours</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '1d')}>in 1 day</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '7d')}>in 7 days</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '14d')}>in 14 days</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '30d')}>in 30 days</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '60d')}>in 60 days</MenuItem>
                    <MenuItem onHeChange={(e: any) => handleExpirationDropdown(e, '90d')}>in 90 days</MenuItem>
                </Menu>
            </Dropdown>
        </AccordionItem>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Assigned to</span>
            <CheckboxGroup valueAsArray={assignedToValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setAssignedToValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                {
                    ((availableFilters?.filter((el: any) => el.name === "AssignedTo")[0] as any)?.possibleValues as any)?.map((el: any) => {
                        return <Checkbox 
                                key={el} 
                                label={el} 
                                
                            >
                                {el}
                            </Checkbox>
                    })
                }
            </CheckboxGroup>
        </AccordionItem>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Channel</span>
            <CheckboxGroup valueAsArray={channelValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setChannelValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                {
                    ((availableFilters?.filter((el: any) => el.name === "Channel")[0] as any)?.possibleValues as any)?.map((el: any) => {
                        return <Checkbox 
                                key={el} 
                                label={el} 
                                
                            >
                                {el}
                            </Checkbox>
                    })
                }
            </CheckboxGroup>
        </AccordionItem>
        <AccordionItem>
            <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Deal type</span>
            <CheckboxGroup valueAsArray={dealTypeValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setDealTypeValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                {
                    ((availableFilters?.filter((el: any) => el.name === "DealType")[0] as any)?.possibleValues as any)?.map((el: any) => {
                        return <Checkbox 
                                key={el} 
                                label={el} 
                                
                            >
                                {el}
                            </Checkbox>
                    })
                }
            </CheckboxGroup>
        </AccordionItem>
        </Accordion>
        <br />
        <div style={{marginBottom: 5}}>
        <img src={process.env.PUBLIC_URL + '/box.svg'} style={{ width: '18px', height: '18px' }} alt="document with a info icon" />
        <text style={{margin: 5, fontWeight: 600}}>Product and term filters</text>
        </div>
        <Accordion>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Line item counts</span>
                Filter to show proposals with at least the entered number of line items or type of the line item. Multiple filters can be applied.<br />
                
                <TextField type="number" style={{marginTop: 10}} label="Total line items" value={totalLineItemsValue === 0 ? undefined : totalLineItemsValue?.toString()} onHeInput={(e: any) => setTotalLineItemsValue(e.target.value)} />
                <TextField type="number" style={{marginTop: 10}} label="Priceable line items" helpText="Confidence threshold is 25 line items." value={priceableValue === 0 ? undefined : priceableValue?.toString()} onHeInput={(e: any) => setPriceableValue(e.target.value)} />
                <TextField type="number" style={{marginTop: 10}} label="Purchasable line items" helpText="Confidence threshold is 10 line items." value={purchasableValue === 0 ? undefined : purchasableValue?.toString()} onHeInput={(e: any) => setPurchasableValue(e.target.value)} />
                <TextField type="number" style={{marginTop: 10}} label="Future discount line items" helpText="Confidence threshold is 140 line items." value={futureDiscountValue === 0 ? undefined : futureDiscountValue?.toString()} onHeInput={(e: any) => setFutureDiscountValue(e.target.value)} />
                <TextField type="number" style={{marginTop: 10}} label="New custom terms" helpText="Confidence threshold is 10 line items." value={newCustomTermValue === 0 ? undefined : newCustomTermValue?.toString()} onHeInput={(e: any) => setNewCustomTermValue(e.target.value)} />
            </AccordionItem>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Scenario</span>
                <CheckboxGroup valueAsArray={scenarioValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setScenarioValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                    {
                        ((availableFilters?.filter((el: any) => el.name === "Scenario")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox 
                                    key={el} 
                                    label={el} 
                                    
                                >
                                    {el}
                                </Checkbox>
                        })
                    }
                </CheckboxGroup>
            </AccordionItem>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Product type</span>
                <CheckboxGroup valueAsArray={productTypeValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setProductTypeValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                    {
                        ((availableFilters?.filter((el: any) => el.name === "ProductType")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox 
                                    key={el} 
                                    label={el} 
                                    
                                >
                                    {el}
                                </Checkbox>
                        })
                    }
                </CheckboxGroup>
            </AccordionItem>
        </Accordion><br />
        <div style={{marginBottom: 5}}>
        <img src={process.env.PUBLIC_URL + '/f029.svg'} style={{ width: '20px', height: '20px' }} alt="document with a info icon" />
        <text style={{margin: 5, fontWeight: 600}}>Agreement filters</text>
        </div>
        <Accordion>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Acceptance mode</span>
                <div>On selection, proposals without an acceptance mode are filtered out.</div><br />
                <CheckboxGroup valueAsArray={acceptanceModeValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setAcceptanceModeValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                    {
                        ((availableFilters?.filter((el: any) => el.name === "AcceptanceMode")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox 
                                    key={el} 
                                    label={el} 
                                    
                                >
                                    {el}
                                </Checkbox>
                        })
                    }
                </CheckboxGroup>
            </AccordionItem>
        </Accordion><br />
        <div style={{marginBottom: 5}}>
        <img src={process.env.PUBLIC_URL + '/e91a.svg'} style={{ width: '20px', height: '20px' }} alt="document with a info icon" />
        <text style={{margin: 5, fontWeight: 600}}>Specialty filters</text>
        </div>
        <Accordion>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Time travel</span>
                <div>Simulate a transaction date to evaluate which proposals may need attention.</div><br />
                <TextField type="date" value={futureTransactionDateValue} onHeInput={(e: any) => setFutureTransactionDateValue(e.target.value)} label="Future transaction date" />
            </AccordionItem>
        </Accordion><br />
        <div style={{marginBottom: 5}}>
        <img src={process.env.PUBLIC_URL + '/labpot.svg'} style={{ width: '20px', height: '20px' }} alt="document with a info icon" />
        <text style={{margin: 5, fontWeight: 600}}>Engineering filters</text>
        </div>
        <Accordion>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Creation source</span>
                <CheckboxGroup valueAsArray={creationSourceValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setCreationSourceValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                    {
                        ((availableFilters?.filter((el: any) => el.name === "CreationSource")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox 
                                    key={el} 
                                    label={el} 
                                    
                                >
                                    {el}
                                </Checkbox>
                        })
                    }
                </CheckboxGroup>
            </AccordionItem>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Traffic source</span>
                <RadioGroup
                    label="Source"
                    value={trafficSource}
                    onHeChange={(e: any) => setTrafficSource(e.target.value)}>
                    <Radio value="Live">Live</Radio>
                    <Radio value="Test">Test</Radio>
                </RadioGroup>
            </AccordionItem>
            <AccordionItem>
                <span className="fw-three" slot="heading" style={{fontWeight: 400}}>Transaction type</span>
                <CheckboxGroup valueAsArray={transactionTypeValuesAsArray ?? []} onHeCheckboxGroupChange={(e: any) => setTransactionTypeValuesAsArray((e.target as checkboxGroup).valueAsArray)}>
                    {
                        ((availableFilters?.filter((el: any) => el.name === "TransactionType")[0] as any)?.possibleValues as any)?.map((el: any) => {
                            return <Checkbox 
                                    key={el} 
                                    label={el} 
                                    
                                >
                                    {el}
                                </Checkbox>
                        })
                    }
                </CheckboxGroup>
            </AccordionItem>
        </Accordion>
        <Button disabled={!anyActiveFilters} onClick={() => handleFilter()} slot="footer" style={{marginBottom: '4vh'}} appearance="primary">
            <Icon style={{marginRight: 5}} name="filter"></Icon>
            Filter
        </Button>
        <Button disabled={!anyActiveFilters} onClick={() => clearFilters()} slot="footer" style={{marginBottom: '4vh', color: anyActiveFilters ? "#0d6efd" : "grey"}} appearance="stealth">
            Clear all
        </Button>
      </FlyInPanel>
      <Dialog
        key="7"
        open={isOpen}
        onHeDialogAfterHide={() => setIsOpen(false)}
      >
        {shareLoading ? <Progress label="Progress" indeterminate /> : <>
        <PageTitle slot="heading">Link copied</PageTitle>
        <p>
          Page filters will be maintained for whoever you share the link with.
        </p>
        </>}
      </Dialog>
      <Dialog
        key="8"
        open={isOpen2}
        onHeDialogAfterHide={() => setIsOpen2(false)}
      >
        <PageTitle slot="heading">Export Excel Error</PageTitle>
        <p>
            No rows found to export. Please try adjusting your filter criteria.
        </p>
      </Dialog>
    </div>)
}