import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import mermaid from "mermaid";
import { Legend } from "./Legend";

  interface MermaidProps {
    chart: string;
    id: string;
    mermaidTheme: string;
    // Add other props here
  }
  
  class MermaidCustom extends React.Component<MermaidProps> {
    componentDidMount() {
      mermaid.contentLoaded();
    }
  
    componentDidUpdate(prevProps: any, prevState: any) {
      if (prevProps.chart !== this?.props.chart ) {
        (document as any)
          .getElementById("mermaid-chart" + this.props.id)
          .removeAttribute("data-processed");

        mermaid.contentLoaded();
      }
    }
  
    render() {
      return (
        <div id={"mermaid-chart" + this.props.id} className="mermaid custom-mermaid">
          {`%%{init: {'theme':'${this.props.mermaidTheme}'}}%%\n` + this.props.chart}
        </div>
      );
    }
  }

export default (param: any) => {
    const [mermaids, setMermaids] = React.useState<any>(null);
    const { id, name } = useParams();
    
    useEffect(() => {
        if (param?.mermaidDiagrams !== null && param?.mermaidDiagrams !== undefined)
        {
            var collectionOfMermaidDiagrams = param?.mermaidDiagrams?.map((diagram: any, idx: number) => {
                return <MermaidCustom mermaidTheme={param?.mermaidTheme ?? 'default'} key={idx} id={idx?.toString()} chart={diagram} />
            });

            setMermaids(collectionOfMermaidDiagrams);
        } else {
            setMermaids(null);
        }
    }, [param?.mermaidDiagrams]);

    return (
        <div style={{marginTop: 10}}>
            {mermaids}
            {param?.legend && param?.loading != true ? <Legend /> : null}
        </div>
    )
}