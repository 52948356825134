import { Stack, StackItem } from "@fluentui/react";
import { accordion, dialog, flyInPanel, skeleton, progress, progressRing, accordionItem, pageTitle, button, subway, commandBar, subwayStop, icon, menu, menuItem, badge, messageBar, createScope, text, textField, select, option, visuallyHidden } from '@harmony/enablers/react';
// @ts-ignore
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import React, { useContext, useEffect } from "react";
import '../index.css';
import TableWithFetch from "./TableWithFetch";
import { useParams } from "react-router";
import { tabs, tab, tabPanel } from '@harmony/enablers/react';
import OverviewTab from "./OverviewTab";
import { PageLayoutContext } from "./PageLayout";
import { QuoteTrackerHttpClient } from "../services/QuoteTrackerHttpClient";
import GenericMermaidTab from "./GenericMermaidTab";
import mermaid from "mermaid";
import { ParticipantsView } from "./ParticipantsView";
import { ConfidenceMeter } from "./ConfidenceMeter";
import ProposalDetailsSkeleton from "./ProposalDetailsSkeleton";
import TabSkeleton from "./TabSkeleton";
import { useSearchParams } from "react-router-dom";

const scope = createScope({
    reactInstance: React
});

const Tabs = scope.forReact(tabs);
const Tab = scope.forReact(tab);
const TabPanel = scope.forReact(tabPanel);

const PageTitle = scope.forReact(pageTitle);
const MessageBar = scope.forReact(messageBar);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const Badge = scope.forReact(badge);
const Dialog = scope.forReact(dialog);
const Progress = scope.forReact(progress);
const CommandBar = scope.forReact(commandBar);
const ProgressRing = scope.forReact(progressRing);
const FlyInPanel = scope.forReact(flyInPanel);

export default () => {
    const { id } = useParams();
    const [ searchParams ] = useSearchParams();
    const validateDate = searchParams.get('validateDate');

    const [name, setName] = React.useState(null);
    const [isFlyInOpen, setIsFlyInOpen] = React.useState(false);

    const toggleFlyIn = () => {
        setIsFlyInOpen(!isFlyInOpen);
    };
    const [proposalStatus, setProposalStatus] = React.useState<any>(null);
    const { myTrackerFetching, setLoadedProposalName, RemoveQuoteFromTrackerList, AddNewQuoteToTrack, myTrackerList, setCurrentErrorMessageWithTimeout, setCurrentWarningMessageWithTimeout, setCurrentSuccessMessageWithTimeout, accessToken, generateToken } = useContext(PageLayoutContext) as any;
    const [currentlyFetchingData, setCurrentlyFetchingData] = React.useState(false);
    const [currentlyFetchingData2, setCurrentlyFetchingData2] = React.useState(false);
    const [confidenceDetails, setConfidenceDetails] = React.useState<any>(null);
    const [timelineDiagrams, setTimelineDiagrams] = React.useState<any>(null);
    const [workflowDiagrams, setWorkflowDiagrams] = React.useState<any>(null);
    const [lineItemsDiagrams, setLineItemsDiagrams] = React.useState<any>(null);
    const [participantsData, setParticipantsData] = React.useState<any>(null);
    const [activeTab, setActiveTabInner] = React.useState('Overview');
    const setActiveTab = (tab: any) => {
        if (tab != undefined)
            setActiveTabInner(tab);
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const [shareLoading, setShareLoading] = React.useState(false);
    
    const handleShareQueryClick = async (e: any) => {
        setIsOpen(true);
        setShareLoading(true);
        const url = window.location.href;
        await navigator.clipboard.writeText(url);
        setShareLoading(false);
    }

    var quoteTimelineService = new QuoteTrackerHttpClient(setCurrentErrorMessageWithTimeout, setCurrentlyFetchingData, console.log, console.log);
    var quoteTimelineService2 = new QuoteTrackerHttpClient(setCurrentErrorMessageWithTimeout, setCurrentlyFetchingData2, console.log, console.log);

    useEffect(() => {
        if (accessToken == null)
        {
            return;
        }

        var confidence = validateDate != null ? `/v1/proposal/${id}/confidence?validateDate=${validateDate}T00:00:00Z` : `/v1/proposal/${id}/confidence`;
        quoteTimelineService.sendCall(confidence, quoteTimelineService.generateCallOptions(accessToken, "", "GET"), setConfidenceDetails);
    }, [accessToken, id]);

    useEffect(() => {
        if (confidenceDetails != null)
        {
            setName(confidenceDetails?.name);
            setLoadedProposalName(confidenceDetails?.name);
            document.title = `${confidenceDetails?.name}`;
        }
    }, [confidenceDetails]);

    const [currentlyFetchingData3, setCurrentlyFetchingData3] = React.useState(false);
    const [proposalDetails, setProposalDetails] = React.useState<any>(null);
    const [approvalDetails, setApprovalDetails] = React.useState<any>(null);
    const [agreementDetails, setAgreementDetails] = React.useState<any>(null);

    var quoteTimelineService3 = new QuoteTrackerHttpClient(setCurrentErrorMessageWithTimeout, setCurrentlyFetchingData3, console.log, console.log);

    useEffect(() => {
        if (proposalDetails?.approval?.length > 0) {
            // Get Microsoft Approval
            var approvalId = proposalDetails?.quote?.header?.approvals?.find((el: any) => el?.type === 1)?.id;
            setApprovalDetails(proposalDetails?.approval?.find((el: any) => el?.id === approvalId) ?? proposalDetails?.approval[0]);
        }

        if (proposalDetails?.agreement)
            setAgreementDetails(proposalDetails?.agreement);

        if (proposalDetails?.quote?.header)
            setProposalStatus(proposalDetails?.quote?.header?.status);
        
        return () => {
            setApprovalDetails(null);
            setAgreementDetails(null);
        };
    }, [proposalDetails]);

    useEffect(() => {
        if (accessToken == null)
        {
            return;
        }
        
        switch (activeTab) {
            case 'Overview':
                if (proposalDetails !== null && proposalDetails?.quote?.id === id)
                {
                    break;
                }

                var getListOfQuotes = `/v1/proposal/${id}`;
                quoteTimelineService3.sendCall(getListOfQuotes, quoteTimelineService.generateCallOptions(accessToken, "", "GET"), setProposalDetails);
                break;
            case 'Participants':
                if (participantsData !== null && participantsData?.quote?.id === id)
                {
                    break;
                }
                var timeline = `/v1/proposal/${id}/participants`;
                quoteTimelineService2.sendCall(timeline, quoteTimelineService2.generateCallOptions(accessToken, "", "GET"), setParticipantsData);
                break;
            case 'Timeline':
                if (timelineDiagrams !== null && timelineDiagrams?.quote?.id === id)
                {
                    break;
                }
                var timeline = `/v1/proposal/${id}/timeline`;
                quoteTimelineService2.sendCall(timeline, quoteTimelineService2.generateCallOptions(accessToken, "", "GET"), setTimelineDiagrams);
                break;
            case 'Activities':
                if (workflowDiagrams !== null && workflowDiagrams?.quote?.id === id)
                {
                    break;
                }
                var timeline = `/v1/proposal/${id}/workflow`;
                quoteTimelineService2.sendCall(timeline, quoteTimelineService2.generateCallOptions(accessToken, "", "GET"), setWorkflowDiagrams);
                break;
            case 'Dependencies':
                if (lineItemsDiagrams !== null && lineItemsDiagrams?.quote?.id === id)
                {
                    break;
                }
                var timeline = `/v1/proposal/${id}/lineitems`;
                quoteTimelineService2.sendCall(timeline, quoteTimelineService2.generateCallOptions(accessToken, "", "GET"), setLineItemsDiagrams);
                break;
            case 'Participants':
                if (confidenceDetails !== null && participantsData?.quote?.id === id)
                {
                    break;
                }
                var timeline = `/v1/proposal/${id}/confidence`;
                quoteTimelineService2.sendCall(timeline, quoteTimelineService2.generateCallOptions(accessToken, "", "GET"), setConfidenceDetails);
                break;
            default:
                break;
        }
    }, [activeTab, id, accessToken]);

    const mapStatusIcon2 = (status: string) => {
        switch (status) {
            case "Active":
                return "completed";
            case "Draft":
                return "openenrollment";
            case "Submitted":
                return "clock";
            case "Completed":
                return "documentapproval";
            case "PurchaseInProgress":
                return "silver";
            case "Error":
                return "errorbadge";
            case "Expired":
                return "hourglass";
            case "Rejected":
                return "buttonx";
            default:
                return "document";
        }
    };
    
    const mapStatusIcon = (status: string) => {
        switch (status) {
            case 'Active':
                return <Icon name="completed" style={{color: 'green'}}>{status}</Icon>;
            case 'Inactive':
                return <Icon name="completed" style={{color: 'red'}}>{status}</Icon>;
            case 'Pending':
                return <Icon name="completed" style={{color: 'orange'}}>{status}</Icon>;
            default:
                return <Icon name="completed" style={{color: 'gray'}}>{status}</Icon>;
        }
    };

    const mapStatusClass = (status: string) => {
        switch (status) {
            case "Completed":
            case "Active":
                return "green";
            case "Draft":
                return "yellow";
            case "Submitted":
            case "PurchaseInProgress":
                return "blue";
            case "Error":
                return "red";
            case "Expired":
            case "Rejected":
                return "red";
            default:
                return "grey";
        }
    };
    
    const statusCss = `
        .yellow-badge::part(base) {
            background: #FFF4CE;
            color: #343a40;
            width: 100%;
        }

        .green-badge::part(base) {
            background: #DFF6DD;
            color: #198754;
            border-radius: 5px;
        }

        .blue-badge::part(base) {
            background: #EFF6FC;
            color: #0d6efd;
            border-radius: 5px;
        }

        .grey-badge::part(base) {
            background: #dee2e6;
            color: #343a40;
        }

        .red-badge::part(base) {
            background: #FDE7E9;
            color: #dc3545;
        }
        div.jsoneditor {
            height: 85vh !important;
        }
        `;

    let anyRulesFailing = confidenceDetails?.rules?.some((el: any) => el?.outcome == "Fail");
    let isUserTracking = myTrackerList?.findIndex((el: any) => el?.quoteId == id) !== -1;
    return currentlyFetchingData3 ? <ProposalDetailsSkeleton /> : (
        <Stack style={{height: '100%'}}>
            <style>
                {statusCss}
            </style>
            <Stack horizontal horizontalAlign="space-between">
                <StackItem style={{marginBottom: 30}}>
                    <PageTitle>
                        {name}
                        <div slot="active-title">
                            <span>
                                {id}
                            </span>
                            <span style={{marginLeft: 10}}>
                                <Badge className={`${mapStatusClass(proposalStatus)}-badge`}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {proposalStatus === 'Completed' || proposalStatus === 'PurchaseInProgress' ? <img src={process.env.PUBLIC_URL + proposalStatus === 'PurchaseInProgress' ? '/eae4.svg' : '/f28b.svg'} style={{marginRight: 5, width: '16px', height: '16px', color: '#198754' }} alt="document with a info icon" />
                                            : <Icon name={mapStatusIcon2(proposalStatus)} style={{marginRight: 5, fontSize: 16}}/>
                                        }
                                        <span style={{fontSize: 14}}>{proposalStatus}</span>
                                    </div>
                                </Badge>
                            </span>
                        </div>
                    </PageTitle>
                </StackItem>
                <StackItem>
                    <CommandBar>
                        <Button onClick={(e: any) => {isUserTracking ? RemoveQuoteFromTrackerList(id) : AddNewQuoteToTrack(id, () => null)}} appearance="command">
                        {
                            myTrackerFetching ? <ProgressRing style={{ '--ring-size': '16px' }} indeterminate /> : isUserTracking ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={process.env.PUBLIC_URL + '/trash.svg'} style={{ color: '--he-accent-foreground-rest', width: '16px', height: '16px', marginRight: 8 }} alt="document with a info icon" />
                                Stop tracking
                            </div> : <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={process.env.PUBLIC_URL + '/e319.svg'} style={{ color: '--he-accent-foreground-rest', width: '16px', height: '16px', marginRight: 8 }} alt="document with a info icon" />
                                        Track proposal
                                    </div>
                        }
                        
                        </Button>
                        <Button appearance="command" onClick={toggleFlyIn}>
                            <Icon slot="start" name="view"></Icon>
                            View JSON
                        </Button>
                        <Button appearance="command" onClick={() => window.open(`https://proposals.microsoft.com/quote/${id}/general`, '_blank')}>
                        <img src={process.env.PUBLIC_URL + '/e8e5.svg'} style={{ color: '--he-accent-foreground-rest', width: '16px', height: '16px', marginRight: 8 }} alt="document with a info icon" />
                            Open in proposal
                        </Button>
                        <Button appearance="command" onClick={handleShareQueryClick}>
                            <Icon slot="start" name="share"></Icon>
                            Share
                        </Button>
                    </CommandBar>
                </StackItem>
            </Stack>
            {
                anyRulesFailing ? (
                    <MessageBar noCloseButton appearance="warning" open>
                        Warning, this proposal needs attention and may be at risk. Expand for more details.
                        <br />
                        <ul>
                        {confidenceDetails?.rules?.map((item: any, idx: any) => item?.outcome === 'Fail' ? <li key={item?.name}>{` ${item?.description}`}</li> : null)}
                        </ul>
                    </MessageBar>
                ) : null
            }            
            <Tabs onHeChange={(e: any) => {setActiveTab(e?.detail?.activeTab?.innerText)}} style={{marginTop: 20}}>
                <Tab key="1">Overview</Tab>
                <Tab key="2">Participants</Tab>
                <Tab key="3">Timeline</Tab>
                <Tab key="4">Activities</Tab>
                <Tab key="5">Dependencies</Tab>
                <Tab key="6">Confidence</Tab> 

                <TabPanel key="7"><OverviewTab proposalDetails={proposalDetails} approvalDetails={approvalDetails} agreementDetails={agreementDetails} /></TabPanel>
                <TabPanel key="8"><ParticipantsView quoteData={activeTab === "Participants" ? participantsData?.proposal : null} /></TabPanel>
                <TabPanel key="9"><GenericMermaidTab loading={currentlyFetchingData2} mermaidTheme={"forest"} mermaidDiagrams={activeTab === "Timeline" ? timelineDiagrams?.mermaidDiagrams : null} /></TabPanel>
                <TabPanel key="10"><GenericMermaidTab loading={currentlyFetchingData2} mermaidTheme={"neutral"} mermaidDiagrams={activeTab === "Activities" ? workflowDiagrams?.mermaidDiagrams : null} /></TabPanel>
                <TabPanel key="11"><GenericMermaidTab loading={currentlyFetchingData2} legend mermaidTheme={"default"} mermaidDiagrams={activeTab === "Dependencies" ? lineItemsDiagrams?.mermaidDiagrams : null} /></TabPanel>
                <TabPanel key="12"><ConfidenceMeter confidenceDetails={activeTab === "Confidence" ? confidenceDetails : null} /></TabPanel>
            </Tabs>
            {currentlyFetchingData2 ? <TabSkeleton /> : null}
            <Dialog
                open={isOpen}
                onHeDialogAfterHide={() => setIsOpen(false)}
            >
                {shareLoading ? <Progress label="Progress" indeterminate /> : <>
                <PageTitle slot="heading">Link copied</PageTitle>
                <p>
                Link of this proposal have been copied to your clipboard.
                </p>
                </>}
            </Dialog>
            <FlyInPanel
                heading={`${proposalDetails?.quote?.header?.name} details`}
                style={{ '--size': 'var(--he-panel-size-large)', maxHeight: '100%' }}
                open={isFlyInOpen}
                onHeFlyInAfterHide={() => setIsFlyInOpen(false)}
            >

                <Editor
                style={{height: '80vh'}}
                value={proposalDetails}
                mode="view"
                allowedModes={['view', 'text']}
                />
                
            </FlyInPanel>
        </Stack>
    );
};