import { Stack, StackItem } from '@fluentui/react';
import { dataGrid, createScope } from '@harmony/enablers/react';
import {
    dropdown,
    button,
    icon,
    menu,
    menuItem,
    commandBar,
    searchBox,
    pageTitle,
    badge,
    pagination,
    checkbox,
    filterPill,
    checkboxGroup,
    buttonGroup,
    flyInPanel,
    divider
  } from '@harmony/enablers/react';
import React, { useEffect } from 'react';
import { formatDate } from './OverviewTab';

const scope = createScope({
    reactInstance: React
});

const styles: {
    [key: string]: React.CSSProperties,
  } = {
    accordionList: {
        margin: '4px 4px',
        overflow: 'auto',
        inset: 0,
        position: 'absolute',
        borderTop: '0px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 'var(--he-spacing-x-small)',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    dropdownContents: {
      minWidth: '240px',
      padding: 'var(--he-spacing-medium)',
    },
    dropdownButtons: {
      marginTop: 'var(--he-spacing-x-large)',
      display: 'flex',
      flexDirection: 'row',
      gap: 'var(--he-spacing-x-small)',
    },
    resetButton: {
      marginLeft: 'auto',
    },
  };

const Dropdown = scope.forReact(dropdown);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const CommandBar = scope.forReact(commandBar);
const SearchBox = scope.forReact(searchBox);
const Divider = scope.forReact(divider);
const DataGrid = scope.forReact(dataGrid);
const Badge = scope.forReact(badge);
const Pagination = scope.forReact(pagination);
const Checkbox = scope.forReact(checkbox);
const CheckboxGroup = scope.forReact(checkboxGroup);
const ButtonGroup = scope.forReact(buttonGroup);
const FilterPill = scope.forReact(filterPill);
const FlyInPanel = scope.forReact(flyInPanel);

export function capitalize(str: string) {
    if (str)
        return str.charAt(0).toUpperCase() + str.slice(1);
}

const generateTags = (tags: any) => {
    return <Stack horizontal tokens={{childrenGap: 4}}>{tags?.map((tag: any) => {
        switch (tag) {
            case 'isPriceable':
                return (<StackItem><Badge style={{background: '#F3F2F1'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/eaed.svg'} style={{marginRight: 5, width: '12px', height: '12px', color: '#198754' }} alt="document with a info icon" />
                        <span style={{fontSize: 12, color: '#323130'}}>Priceable</span>
                    </div>
                </Badge></StackItem>);
            case 'isPurchasable':
                return (<StackItem><Badge style={{background: '#F3F2F1'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name="shoppingcart" style={{marginRight: 5, fontSize: 12}} />
                        <span style={{fontSize: 12, color: '#323130'}}>Purchasable</span>
                    </div>
                </Badge></StackItem>);
            case 'isNewCustomTerm':
                return (<StackItem><Badge style={{background: '#F3F2F1'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/e4aa.svg'} style={{marginRight: 5, width: '12px', height: '12px', color: '#198754' }} alt="document with a info icon" />
                        <span style={{fontSize: 12, color: '#323130'}}>New custom term</span>
                    </div>
                </Badge></StackItem>);
            case 'isFutureDiscount':
                return (<StackItem><Badge style={{background: '#F3F2F1'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name="tag" style={{marginRight: 5, fontSize: 12}} />
                        <span style={{fontSize: 12, color: '#323130'}}>Future discount</span>
                    </div>
                </Badge></StackItem>);
            default:
                break;
        }
    })
    }</Stack>
};

const generateTagsForTableData = (lineItem: any) => {
    const tags = [];
    if (lineItem?.limitCategory != null) {
        if (lineItem?.limitCategory?.includes('Pricing')) {
            tags.push('isPriceable');
        }

        if (lineItem?.limitCategory?.includes('Purchase')) {
            tags.push('isPurchasable');
        }
        
        if (lineItem?.limitCategory?.includes('DFD')) {
            tags.push('isFutureDiscount');
        }
    }

    if (!tags.includes('isFutureDiscount') && lineItem?.charge?.productIdentifier?.productId === 'DZH318Z0BWV5')
    {
        tags.push('isFutureDiscount');
    }

    if (!tags.includes('isPriceable') && !tags.includes('isFutureDiscount'))
    {
        tags.push('isPriceable');
    }

    if (lineItem?.charge?.productIdentifier?.productType === 'RequestNewCustomTerm') {
        tags.push('isNewCustomTerm');
    }

    return tags;
}

export default (param: any) => {
    const [rows, setRows] =  React.useState<any>([]);
    const [filteredRows, setFilteredRows] =  React.useState<any>({rows: []});
    const [searchTerm, setSearchTerm] = React.useState('');
    const [currentLineItemDetail, setCurrentLineItemDetail] = React.useState<any>(null);
    const [pTypeFiltersCount, setFilterCounts] = React.useState<any>({});

    const showDetails = (id: string) => {
        setIsMediumOpen(true);
        setCurrentLineItemDetail(param?.lineItems?.find((el: any) => el?.id === id));
    };

    const [productTypeFilterPill, setProductTypeFilterPill] = React.useState<any>(null);
    const [attentionFilterPill, setAttentionFilterPill] = React.useState<any>(null);
    
    useEffect(() => {
        if (param?.lineItems?.length > 0) {
            const pTypeFiltersCount: any = {};
            param?.lineItems?.forEach((el: any, idx: any) => {
                let name = el?.charge?.productIdentifier?.productType;
                pTypeFiltersCount[name] = pTypeFiltersCount[name] ? pTypeFiltersCount[name] + 1 : 1;
            })
            setFilterCounts(pTypeFiltersCount);
            let nRows = param?.lineItems?.map((el: any) => {
                return {
                    id: el?.id,
                    cells: {
                        name: `${el?.charge?.productIdentifier?.productFamily ?? '(-)'} - ${el?.productDisplayName ?? '(-)'}`,
                        productType: el?.charge?.productIdentifier?.productType,
                        startDate: formatDate(el?.startDate),
                        endDate: formatDate(el?.endDate),
                        needAttention: el?.needAttention,
                        tags: generateTagsForTableData(el),
                        details: ''
                    }
                }
            });
            
            setRows(nRows);
        }

    }, [param?.lineItems]);

    const handleSortLineItem = (e: any) => {
        const { isAscending, sortBy } = e.detail;

        const sortData = (a: any, b: any) => {
            const aValue = a.cells[sortBy]
            const bValue = b.cells[sortBy]
            
            if (sortBy === 'needAttention') {
                const order = ['Unknown', 'No', 'Maybe', 'Yes']; // Define your order of precedence
                const aIndex = order.indexOf(a.cells.needAttention);
                const bIndex = order.indexOf(b.cells.needAttention);
                
                if (aIndex === bIndex) return 0;
                return isAscending ? (aIndex < bIndex ? 1 : -1) : (aIndex > bIndex ? 1 : -1);
            }

            if (aValue == null || bValue == null) {
                if (aValue == null && bValue == null) return 0;
                return aValue == null ? (isAscending ? -1 : 1) : (isAscending ? 1 : -1);
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                // Check if the strings are dates
                const aDate = new Date(aValue);
                const bDate = new Date(bValue);
                if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
                    // Check if the strings are dates
                    const aDate = new Date(aValue);
                    const bDate = new Date(bValue);
                    if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
                        return isAscending ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
                    }
                    return isAscending ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
                }
                return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                return isAscending ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'object' && typeof bValue === 'object') {
                // Handle integer fields represented as objects
                if (!aValue.hasOwnProperty('value')) {
                    aValue.value = 0;
                }

                if (!bValue.hasOwnProperty('value')) {
                    bValue.value = 0;
                }

                if (aValue.hasOwnProperty('value') && bValue.hasOwnProperty('value')) {
                    return isAscending ? aValue.value - bValue.value : bValue.value - aValue.value;
                }
            }
            return 0;
        };
    
        const nRows = rows?.sort(sortData);
        
        setFilteredRows({rows: nRows});
    };

    useEffect(() => {
        if (rows?.length > 0) {
            let nRows = rows;
            
            if (searchTerm?.length > 0)
            {
                nRows = nRows
                    ?.filter((el: any) => el?.cells?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
            }

            if (productTypeFilterPill != null)
            {
                nRows = nRows
                    ?.filter((el: any) => el?.cells?.productType && productTypeFilterPill?.findIndex((el2: any) => el2 === el?.cells?.productType) !== -1);
            }

            if (attentionFilterPill != null)
            {
                nRows = nRows
                    ?.filter((el: any) => attentionFilterPill?.includes(el?.cells?.needAttention));
            }    
    
            setFilteredRows({rows: nRows});
        }
    }, [rows, searchTerm, productTypeFilterPill, attentionFilterPill]);
    
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pagedRows, setPagedRows] = React.useState<any>([]);
    const [productTypeFilter, setProductTypeFilter] = React.useState<any>(null);
    const [attentionFilter, setAttentionFilter] = React.useState<any>(null);

    const pageSize = 15;
    const pageArray = () => {
        const pagedRows = filteredRows?.rows?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        const pageCount = Math.ceil(rows?.length / pageSize);
        
        setPagedRows(pagedRows);
        setPageCount(pageCount);
    };

    const handlePageChange = (e: any) => {
        setPageNumber(e.detail);
    };

    const [isMediumOpen, setIsMediumOpen] = React.useState(false);

    useEffect(pageArray, [filteredRows, pageNumber, pageSize]);

    const handleCheckboxProduct = (e: any) => {
        setProductTypeFilter(e.target.valueAsArray);
    };

    const handleCheckboxAttention = (e: any) => {
        setAttentionFilter(e.target.valueAsArray);
    };

    const reapplyPillsToTemps = () => {
        setProductTypeFilter(productTypeFilterPill);
        setAttentionFilter(attentionFilterPill);
    };

    const [filterDropdownStates, setFilterDropdownStates] = React.useState<any>({
        pType: false,
        attention: false,
    });
    const [isOpen, setIsOpen] = React.useState(false);
    
    const pTypeFilters = Object.keys(pTypeFiltersCount ?? {});
    return (<Stack>
        <CommandBar key="1">
            <Dropdown onHeDropdownRequestClose={(e: any) => {e?.detail?.source === "change" && e.preventDefault()}} key="2" slot="action-end" fixedPlacement open={isOpen} onHeDropdownShow={(e: any) => setIsOpen(true)} onHeDropdownHide={(e: any) => setIsOpen(false)}>
            <Button slot="trigger" caret style={{marginRight: 5}} appearance="command">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Icon name="filter" style={{color: "#0d6efd", marginRight: 5}} label="Filter"></Icon>
                    Filter
                </div>
            </Button>

            <Menu style={{padding: 15}}>
                <span style={{fontWeight: 500}}>Product type</span>
                    {pTypeFilters?.filter((el3: any, idx: any) =>  pTypeFiltersCount[el3] !== undefined && pTypeFiltersCount[el3] > 0)?.map((el: any, idx: any) => <MenuItem onClick={(e: any) => {
                        e?.target?.checked ? setProductTypeFilter([...productTypeFilter ?? [], el]) : 
                            setProductTypeFilter(productTypeFilter?.filter((el2: any) => el2 !== el))}} role="menuitemcheckbox">{`${el} (${pTypeFiltersCount[el] ?? ''})`}</MenuItem>)}
                <Divider />
                <span style={{fontWeight: 500}}>Needs attention?</span>
                    {['Yes', 'Maybe', 'No']?.map((el: any) => <MenuItem onClick={(e: any) => {
                        e?.target?.checked ? setAttentionFilter([...attentionFilter ?? [], el]) : 
                            setAttentionFilter(attentionFilter?.filter((el2: any) => el2 !== el))}} role="menuitemcheckbox">{el}</MenuItem>)}
                <div style={styles.dropdownButtons}>
                    <Button disabled={((productTypeFilter?.length === productTypeFilterPill?.length && productTypeFilterPill?.filter((el: any) => productTypeFilter?.findIndex((el2: any) => el === el2) === -1)?.length === 0) &&
                    (attentionFilter?.length === attentionFilterPill?.length && attentionFilterPill?.filter((el: any) => attentionFilter?.findIndex((el2: any) => el === el2) === -1)?.length === 0)) ||
                    (attentionFilter?.length === 0 && productTypeFilter?.length === 0) || (attentionFilter === null && productTypeFilter === null)}
                    onClick={() => {setProductTypeFilterPill(productTypeFilter?.length === 0 ? null : productTypeFilter);setAttentionFilterPill(attentionFilter?.length === 0 ? null : attentionFilter);setIsOpen(false)}} appearance="primary">
                        Apply
                    </Button>
                    <Button onClick={() => {setIsOpen(false);reapplyPillsToTemps()}}>Cancel</Button>
                </div>
            </Menu>
            </Dropdown>
            <SearchBox key="3" slot="search" onHeInput={(e: any) => setSearchTerm(e?.target?._value)} value={searchTerm} label="Search" hideLabel></SearchBox>
            <div key="1" style={{margin: 5}}>
                {attentionFilterPill || productTypeFilterPill !== null ? <text style={{fontSize: '15px', fontWeight: 600}}>Filtered by:</text> : null}
                {productTypeFilterPill !== null ? <><ButtonGroup key="1" style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['pType']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, pType: false})} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, pType: true})}  style={{marginLeft: 5}} fixedPlacement>
                        <FilterPill slot="trigger" values={productTypeFilterPill}>
                        Product type:
                        </FilterPill>
                        <Menu>
                        <div style={styles.dropdownContents}>
                            <CheckboxGroup
                            valueAsArray={productTypeFilter ?? []}
                            onHeCheckboxGroupChange={handleCheckboxProduct}
                            >
                            {
                            pTypeFilters?.filter((el3: any, idx: any) =>  pTypeFiltersCount[el3] !== undefined && pTypeFiltersCount[el3] > 0)?.map((el: any) => {
                                return <Checkbox key={el} value={el} >{`${el} (${pTypeFiltersCount[el]})`}</Checkbox>
                            })
                            }
                            </CheckboxGroup>

                            <div style={styles.dropdownButtons}>
                            <Button disabled={(productTypeFilter?.length === productTypeFilterPill?.length && productTypeFilterPill?.filter((el: any) => productTypeFilter?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || productTypeFilter?.length === 0}
                            onClick={() => {setProductTypeFilterPill(productTypeFilter);setFilterDropdownStates({...filterDropdownStates, pType: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => {setFilterDropdownStates({...filterDropdownStates, pType: false});reapplyPillsToTemps()}}>Cancel</Button>
                            </div>
                        </div>
                        </Menu>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => { setProductTypeFilterPill(null); }} />
                    </ButtonGroup>
                    </> : null}
                    {attentionFilterPill !== null ? <><ButtonGroup key="1" style={{marginTop: 5}}><Dropdown className="pad-top" open={filterDropdownStates['attention']} onHeDropdownHide={() => setFilterDropdownStates({...filterDropdownStates, attention: false})} onHeDropdownShow={() => setFilterDropdownStates({...filterDropdownStates, attention: true})}  style={{marginLeft: 5}} fixedPlacement>
                        <FilterPill slot="trigger" values={attentionFilterPill}>
                        Needs attention?:
                        </FilterPill>
                        <Menu>
                        <div style={styles.dropdownContents}>
                            <CheckboxGroup
                            valueAsArray={attentionFilter ?? []}
                            onHeCheckboxGroupChange={handleCheckboxAttention}
                            >
                            {
                            ["Yes", "Maybe", "No"]?.map((el: any) => {
                                return <Checkbox key={el}>{el}</Checkbox>
                            })
                            }
                            </CheckboxGroup>

                            <div style={styles.dropdownButtons}>
                            <Button disabled={(attentionFilter?.length === attentionFilterPill?.length && attentionFilterPill?.filter((el: any) => attentionFilter?.findIndex((el2: any) => el === el2) === -1)?.length === 0) || attentionFilter?.length === 0}
                            onClick={() => {setAttentionFilterPill(attentionFilter);setFilterDropdownStates({...filterDropdownStates, attention: false})}} appearance="primary">
                                Apply
                            </Button>
                            <Button onClick={() => {setFilterDropdownStates({...filterDropdownStates, attention: false});reapplyPillsToTemps()}}>Cancel</Button>
                            </div>
                        </div>
                        </Menu>
                        </Dropdown>
                        <FilterPill clear onClick={(e: any) => { setAttentionFilterPill(null); }} />
                    </ButtonGroup>
                    </> : null}
                    {
                        productTypeFilterPill != null || attentionFilterPill != null ? 
                        <Button onClick={() => {setAttentionFilterPill(null);setProductTypeFilterPill(null);reapplyPillsToTemps()}} style={{marginLeft: 5}} appearance="stealth">
                            Clear all
                        </Button> : null
                    }
            </div>
        </CommandBar>
        <DataGrid key="2" rows={pagedRows} onHeSort={handleSortLineItem} columns={columns}>
            {
                pagedRows?.map((row: any) => {
                    return (<>
                    <div
                        slot={`details-${row?.id}`}
                        key={`details-${row?.id}`}>
                        <Button appearance='link' onClick={(e: any) => showDetails(row?.id)}>Details</Button>
                    </div>
                    <div
                        slot={`tags-${row?.id}`}
                        key={`tags-${row?.id}`}>
                            {generateTags(row?.cells?.tags)}
                    </div>
                    <div
                        slot={`needAttention-${row?.id}`}
                        key={`needAttention-${row?.id}`}>
                        {row.cells?.needAttention == "Yes" ? 
                        <span style={{color: '#A80000', display: 'flex', alignItems: 'center' }}>
                            <span>Yes</span><Icon name="warning" style={{color: '#A80000', marginLeft: 5, marginBottom: 2}} />
                        </span> : row.cells?.needAttention}
                    </div>
                    </>)
                })
            }
            {rows?.length > 0 ? <Pagination
                slot="pagination"
                value={pageNumber}
                pageCount={pageCount}
                onHePageChange={handlePageChange}
            /> : null}
            <div slot="no-records" style={{margin: 50}}>
                {param?.lineItems?.length === 0 ? <span>No results found. Try adjusting your filter criteria.</span>
                : <div><Icon name="clearfilter" style={{ fontSize: '20px', margin: '10px', padding: '18px', backgroundColor: 'rgba(206, 206, 206, 0.5)', borderRadius: '50%' }}/><br />
                <span>No results found. Try adjusting your filter criteria.</span><br />
                <Button disabled={productTypeFilterPill === null && searchTerm?.length === 0} onClick={() => {setProductTypeFilterPill(null);setSearchTerm('')}} slot="footer" style={{marginBottom: '4vh', color: productTypeFilterPill === null && searchTerm?.length === 0 ? "grey" : "#0d6efd"}} appearance="stealth">
                    <u>Clear all</u>
                </Button>
                </div>}
            </div>
        </DataGrid>
        <FlyInPanel
                heading={`${currentLineItemDetail?.charge?.productIdentifier?.productFamily ?? '(-)'} - ${currentLineItemDetail?.productDisplayName ?? '(-)'} details`}
                style={{ '--size': 'var(--he-panel-size-medium)' }}
                open={isMediumOpen}
                onHeFlyInAfterHide={() => setIsMediumOpen(false)}
            >
                <Stack>
                    <Stack style={{fontSize: 14}} horizontal>
                        <StackItem>
                            <div style={{fontWeight: 500}}>Needs attention?:</div>
                            <div style={{marginTop: 10, fontWeight: 500}}>Reason:</div>
                        </StackItem>
                        <StackItem style={{marginLeft: 10}}>
                            <div key="1">{currentLineItemDetail?.needAttention ?? '-'}</div>
                            <div key="2" style={{marginTop: 10}}>{currentLineItemDetail?.reason ?? '-'}</div>
                        </StackItem>
                    </Stack>
                    <br />
                    <h5>Product attributes</h5>
                    <Stack style={{fontSize: 14}} horizontal>
                        <StackItem>
                            <div key="1" style={{fontWeight: 500}}>Product family:</div>
                            <div key="222" style={{marginTop: 10, fontWeight: 500}}>Product name:</div>
                            <div key="2" style={{marginTop: 10, fontWeight: 500}}>Line item summary:</div>
                            <div key="3" style={{marginTop: 10, fontWeight: 500}}>Product ID:</div>
                            <div key="4" style={{marginTop: 10, fontWeight: 500}}>Product type:</div>
                            <div key="5" style={{marginTop: 10, fontWeight: 500}}>SKU ID:</div>
                            <div key="6" style={{marginTop: 10, fontWeight: 500}}>Status:</div>
                            <div key="7" style={{marginTop: 10, fontWeight: 500}}>Start date:</div>
                            <div key="8" style={{marginTop: 10, fontWeight: 500}}>End date:</div>
                        </StackItem>
                        <StackItem style={{marginLeft: 10}}>
                            <div key="33">{currentLineItemDetail?.charge?.productIdentifier?.productFamily ?? '-'}</div>
                            <div key="33213" style={{marginTop: 10}}>{currentLineItemDetail?.productDisplayName ?? '-'}</div>
                            <div key="1" style={{marginTop: 10}}>{currentLineItemDetail?.productDisplaySummary ?? '-'}</div>
                            <div key="2" style={{marginTop: 10}}>{currentLineItemDetail?.charge?.productIdentifier?.productId ?? '-'}</div>
                            <div key="3" style={{marginTop: 10}}>{currentLineItemDetail?.charge?.productIdentifier?.productType ?? '-'}</div>
                            <div key="4" style={{marginTop: 10}}>{currentLineItemDetail?.charge?.productIdentifier?.skuId ?? '-'}</div>
                            <div key="5" style={{marginTop: 10}}>{currentLineItemDetail?.status ?? '-'}</div>
                            <div key="6" style={{marginTop: 10}}>{formatDate(currentLineItemDetail?.startDate ?? '-')}</div>
                            <div key="7" style={{marginTop: 10}}>{formatDate(currentLineItemDetail?.endDate ?? '-')}</div>
                        </StackItem>
                    </Stack>
                    <br />
                    <h5>Price attributes</h5>
                    <Stack style={{fontSize: 14}} horizontal>
                        <StackItem>
                            <div key="1" style={{fontWeight: 500}}>Pricing currency:</div>
                            <div key="2" style={{marginTop: 10, fontWeight: 500}}>Purchase term units:</div>
                            <div key="3" style={{marginTop: 10, fontWeight: 500}}>In USD:</div>
                        </StackItem>
                        <StackItem style={{marginLeft: 10}}>
                            <div>{currentLineItemDetail?.charge?.pricingCurrency ?? '-'}</div>
                            <div key="1" style={{marginTop: 10}}>{currentLineItemDetail?.purchaseInstruction?.purchaseTermUnits ? parseInt(currentLineItemDetail?.purchaseInstruction?.purchaseTermUnits)?.toLocaleString() : '-'}</div>
                            <div key="2" style={{marginTop: 10}}>{currentLineItemDetail?.purchaseInstruction?.purchaseTermUnitsUSD ? parseInt(currentLineItemDetail?.purchaseInstruction?.purchaseTermUnitsUSD)?.toLocaleString() : '-'}</div>
                        </StackItem>
                    </Stack>
                    <br />
                    {currentLineItemDetail?.azureConsumptionCommitment != null ? <><h5>MACC attributes</h5>
                    <Stack style={{fontSize: 14}} horizontal>
                        <StackItem>
                            <div key="1" style={{fontWeight: 500}}>MACC ID:</div>
                            <div key="2" style={{marginTop: 10, fontWeight: 500}}>Contributors allowed?:</div>
                        </StackItem>
                        <StackItem style={{marginLeft: 10}}>
                            <div>{currentLineItemDetail?.azureConsumptionCommitment?.maccId ?? '-'}</div>
                            <div key="1" style={{marginTop: 10}}>{currentLineItemDetail?.azureConsumptionCommitment?.isContributorsAllowed?.toString() ?? '-'}</div>
                        </StackItem>
                    </Stack>
                    <br /></> : null}
                    <h5>Additional attributes</h5>
                    <Stack style={{fontSize: 14}} horizontal>
                        <StackItem style={{flexShrink: 0}}>
                            <div key="1" style={{fontWeight: 500}}>Tags:</div>
                            <div key="2" style={{marginTop: 10, fontWeight: 500}}>Line item ID:</div>
                            <div key="3" style={{marginTop: 10, fontWeight: 500}}>Customer intent:</div>
                            <div key="4" style={{marginTop: 10, fontWeight: 500}}>Purchase provider type:</div>
                            <div key="5" style={{marginTop: 10, fontWeight: 500}}>Catalog claims:</div>
                        </StackItem>
                        <StackItem style={{marginLeft: 10, maxWidth: '100%'}}>
                            <div>{generateTags(generateTagsForTableData(currentLineItemDetail))}</div>
                            <div key="1" style={{marginTop: 4}}>{currentLineItemDetail?.id ?? '-'}</div>
                            <div key="2" style={{marginTop: 10}}>{currentLineItemDetail?.customerIntent ?? '-'}</div>
                            <div key="3" style={{marginTop: 10}}>{currentLineItemDetail?.purchaseProviderName ?? '-'}</div>
                            <div key="4" style={{lineBreak: 'anywhere', overflowWrap: 'break-word', marginTop: 10}}>{JSON.stringify(currentLineItemDetail?.catalogClaims) ?? '-'}</div>
                        </StackItem>
                    </Stack>
                    <br />
                </Stack>
        </FlyInPanel>
    </Stack>)
};

var columns = [
    {
        field: `name`,
        content: `Name`,
        sortable: true,
        display: {
            width: '500px',
            maxWidth: '500px'
        }
    },
    {
        field: `productType`,
        content: `Product type`,
        sortable: true,
        display: {
            width: '150px',
            maxWidth: '150px'
        }
    },
    {
        field: `startDate`,
        content: `Starts`,
        sortable: true,
        display: {
            width: '200px',
            maxWidth: '200px'
        }
    },
    {
        field: `endDate`,
        content: `Ends`,
        sortable: true,
        display: {
            width: '200px',
            maxWidth: '200px'
        }
    },
    {
        field: `needAttention`,
        content: `Needs attention?`,
        sortable: true,
        display: {
            width: '150px',
            maxWidth: '150px'
        }
    },
    {
      field: `tags`,
      content: `Tags`,
      sortable: false,
      display: {
          width: '400px',
          maxWidth: '400px'
      }
    },
    {
        field: `details`,
        content: ``,
        sortable: false,
        display: {
            width: '100px',
            maxWidth: '100px'
        }
      }
];
