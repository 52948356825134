import { FontIcon, MessageBarType, initializeIcons } from '@fluentui/react';
import { MessageBar } from '@fluentui/react';
import {
  button,
  createScope, dataGrid,
  divider,
  dropdown,
  icon,
  layout,
  menu,
  menuItem,
  navHeader,
  persona,
  taskItem,
  taskMenu,
  progressRing,
  tooltip,
  visuallyHidden
} from '@harmony/enablers/react';
import React, { useEffect, useRef, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { loginRequest } from '../authConfig';
import "../pageLayout.css";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { reverse } from 'dns';
import { PublicClientApplication } from '@azure/msal-browser';
import { aadConfig, accessRequest } from '../aadTokenConfig';
import AccessPackages from './AccessPackages';
import jwt_decode from 'jwt-decode';
import '../nav.css';
import { IQuoteTrackerHttpClient, QuoteTrackerHttpClient } from '../services/QuoteTrackerHttpClient';

initializeIcons();

export const PageLayoutContext = React.createContext(null);

const scope = createScope({
  reactInstance: React
});

const Tooltip = scope.forReact(tooltip);
const Dropdown = scope.forReact(dropdown);
const NavHeader = scope.forReact(navHeader);
const Icon = scope.forReact(icon);
const Layout = scope.forReact(layout);
const Button = scope.forReact(button);
const VisuallyHidden = scope.forReact(visuallyHidden);
const Persona = scope.forReact(persona);
const TaskMenu = scope.forReact(taskMenu);
const TaskItem = scope.forReact(taskItem);
const Divider = scope.forReact(divider);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const ProgressRing = scope.forReact(progressRing);

function handleLogin(instance: any) {
  instance.loginRedirect(loginRequest).catch((e: any) => {
    console.error(e);
  });
}

function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: any) => {
    console.error(e);
  });
}

const IsTokenExpired = (token: any) => {
  var decodedToken = jwt_decode(token);
  if ((decodedToken as any).exp < Date.now() / 1000) {
    localStorage.clear();
    return true;
  }
  return false;
}

const getNameFromToken = (token: any) => {
  var decodedToken = jwt_decode(token);
  return (decodedToken as any).name;
}

export const GetPrincipalId = (token: any) => {
  if (token?.length > 0)
  {
    var decodedToken = jwt_decode(token);
    return (decodedToken as any).upn ?? (decodedToken as any).preferred_username;
  }
  return null;
}

const DataGrid = scope.forReact(dataGrid);

const aadInstance = new PublicClientApplication(aadConfig);


export enum AppRoles {
  Admin = "Admin",
  Copilot = "Copilot"
};

// Renders the navbar component with a sign-in button if a user is not authenticated.
export const PageLayout = (props: any) => {
  const { id } = useParams();
  const [isInit, setIsInit] = useState(false);
  const [myTrackerData, setMyTrackerData] = useState<any>({id: ''});
  const [myTrackerList, setMyTrackerList] = useState<any>([]);
  const [trackedQuotesRefresh, triggerTrackedQuotesRefresh] = useState(false);
  const [accessToken, setAccessToken] = React.useState<any>(null);
  const [loadedProposalName, setLoadedProposalName] = useState(null);
  

  const layoutRef = useRef(null);
  const [menuIsExpanded, setMenuIsExpanded] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("");
  const { instance, accounts } = useMsal();
  
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [latestIds, setLatestIds] = useState<any>([]);
  const navigate = useNavigate();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [currentWarningMessage, setCurrentWarningMessage] = useState("");
  const [successMessageClassName, setSuccessMessageClassName] = useState("custom-message-bar");
  const [errorMessageClassName, setErrorMessageClassName] = useState("custom-message-bar");
  const [warningMessageClassName, setWarningMessageClassName] = useState("custom-message-bar");
  const [currentSuccessMessage, setCurrentSuccessMessage] = useState("");
  const [currentErrorMessage, setCurrentErrorMessage] = useState("");
  const [currentTimeoutId, setCurrentTimeout] = useState("");
  const [currentWarningTimeoutId, setCurrentWarningTimeout] = useState("");
  const [currentlyFetchingData, setCurrentlyFetchingData] = useState(false);

  function S2SToken() {
    const aadrequest = {
      ...accessRequest,
      account: accounts[0]
    };

    aadInstance
      .acquireTokenSilent(aadrequest)
      .then((response: any) => {
        setAccessToken(response.accessToken);
      })
      .catch((e) => {
        {
          console.log("error from token ", e);
          aadInstance.acquireTokenPopup(aadrequest).then((response: any) => {
            setAccessToken(response.accessToken);
          });
        }
      });
  };

  React.useEffect(() => {
    if (isAuthenticated)
    {
      S2SToken();
    }

    if (location?.pathname === "/" && isAuthenticated && accessToken != null)
    {
      setSelectedMenu("listselectsetvalue");
    }
  }, [isAuthenticated]);

  const clearSuccessMessage = () => {
    setSuccessMessageClassName("custom-message-bar")
    setCurrentSuccessMessage("")
  };

  const setCurrentErrorMessageWithTimeout = (errorMessage: string) => {
    if (errorMessage.includes("signal is aborted without reason"))
      return;

    setCurrentErrorMessage(errorMessage);
    clearTimeout(currentTimeoutId);
    var timeout = setTimeout(clearErrorMessage, 8500);
    setCurrentTimeout(timeout.toString());
  };

  const clearErrorMessage = () => {
    setErrorMessageClassName("custom-message-bar")
    setCurrentErrorMessage("")
  };
  
  const setCurrentSuccessMessageWithTimeout = (successMessage: string) => {
    setCurrentSuccessMessage(successMessage);
    clearTimeout(currentTimeoutId);
    var timeout = setTimeout(clearSuccessMessage, 8500);
    setCurrentTimeout(timeout.toString());
  };

  const clearWarningMessage = () => {
    setWarningMessageClassName("custom-message-bar")
    setCurrentWarningMessage("")
  };

  const setCurrentWarningMessageWithTimeout = (warningMessage: string) => {
    setCurrentWarningMessage(warningMessage);
    clearTimeout(currentWarningTimeoutId);
    var timeout = setTimeout(clearWarningMessage, 8500);
    setCurrentWarningTimeout(timeout.toString());
  };

  var quoteTimelineService = new QuoteTrackerHttpClient(setCurrentErrorMessageWithTimeout, setCurrentlyFetchingData, console.log, console.log);
  useEffect(() => {
    const initializeAAD = async () => {
      await aadInstance.initialize();
      setIsInit(true);
    };
    initializeAAD();
  }, []);

  const myTrackerGetCreatedBySetter = (param: any) =>
  {
      // create new tracker
      if (param.length == 0)
      {
          var requestForFirstTracker = {name: "My Tracker", rules: []}
          const firstTrackerSetter = (param2: any) => {
              setMyTrackerData(param2)
          }
          var createTrackerUrl = `/v1/quotetracker`
          quoteTimelineService.sendCall(createTrackerUrl, quoteTimelineService.generateCallOptions(accessToken, JSON.stringify(requestForFirstTracker), "POST"), firstTrackerSetter);
          return;
      }
  
      if (param[0] == undefined) {
          return;
      }
      // display one tracker
      setMyTrackerData(param[0]);
  };

  const AddNewQuoteToTrack = (payload: any, dismissPanel: any) => {
      var someQuotesAlreadyThere = false;
      if (payload?.length >= 12)
      {
          payload = payload.split(",").map((el: any) => {
              return {attributeName: "QuoteId", value: el.trim()}
          })
      }
      else if (payload?.length == 0)
      {
          if (dismissPanel != null)
          {
            dismissPanel()
          }
          setCurrentErrorMessageWithTimeout("Please enter a valid list of Quote Ids or a single Quote Id.")
          return;
      }
      else
      {
          payload = [{"attributeName": "QuoteId", "value": payload}]
      }

      let filteredPayload: any[] = [];

      filteredPayload = payload.filter((el: any) => myTrackerList?.findIndex((el2: any) => el2.quoteId == el.value) === -1)

      if (payload.length > filteredPayload.length)
      {
          someQuotesAlreadyThere = true;
          if (filteredPayload.length == 0)
          {
              dismissPanel()
              setCurrentSuccessMessageWithTimeout(`Given Quotes are already being tracked!`)
              return;
      }
      }

      const addNewQuoteToTrackSetter = (param: any) => {
          setMyTrackerData(param);
          triggerTrackedQuotesRefresh(!trackedQuotesRefresh)
          
          
          const distinctValues = filteredPayload?.reduce((acc: any[], el: any) => {
              if (!acc.includes(el.value)) {
                  acc.push(el.value);
              }
              return acc;
          }, []);
          let validDistinctValues: any[] = [];
          let invalidDistinctValues: any[] = [];
          
          distinctValues.forEach((el: any) => {
          if (param.rules.findIndex((el2: any) => el2.value == el) > -1)
          {
              validDistinctValues = [el, ...validDistinctValues]
          } else {
              invalidDistinctValues = [el, ...invalidDistinctValues]
          }
      });

      var updateMessage = validDistinctValues?.map((el: any) => el).join(", ");
      var warningMessage = invalidDistinctValues?.map((el: any) => el).join(", ");

      dismissPanel()
      if (someQuotesAlreadyThere && validDistinctValues?.length > 0)
      {
      setCurrentSuccessMessageWithTimeout(`${updateMessage} were added successfully! (Some Quotes are already in the list.)`)
      } else if (validDistinctValues?.length > 0) {
      setCurrentSuccessMessageWithTimeout(`${updateMessage} were added successfully!`)
      } else {
      if (someQuotesAlreadyThere)
      {
          setCurrentErrorMessageWithTimeout("Some Quotes are already in the list, no *new* Quotes added to the list.")
      }
      else {
          setCurrentErrorMessageWithTimeout("No Quotes added to the list.")
      }
      }

      if (invalidDistinctValues?.length > 0)
      {
      setCurrentWarningMessage(`Unable to add ${warningMessage}.`)
      }

      return;
  }
  var addNewQuotesUrl = "/v1/quotetracker/quotes/";
  addNewQuotesUrl += myTrackerData?.id;

  quoteTimelineService.sendCall(addNewQuotesUrl, quoteTimelineService.generateCallOptions(accessToken, JSON.stringify(filteredPayload), "POST"), addNewQuoteToTrackSetter);
  }

  const RemoveQuoteFromTrackerList = (quoteId: any) => {
    const addNewQuoteToTrackPayload = [{attributeName: "QuoteId", value: quoteId}];
    
    const removeQuoteFromTrackerList = (param: any) => {
        triggerTrackedQuotesRefresh(!trackedQuotesRefresh)
        setCurrentSuccessMessageWithTimeout(`${quoteId} has been removed from the list to track!`)
    };

    var createTrackerUrl = `/v1/quotetracker/quotes/${myTrackerData?.id}`;
    quoteTimelineService.sendCall(createTrackerUrl, quoteTimelineService.generateCallOptions(accessToken, JSON.stringify(addNewQuoteToTrackPayload), "DELETE"), removeQuoteFromTrackerList);
  }

  const FetchMyTracker = (quoteTimelineService: IQuoteTrackerHttpClient, accessToken: any, myTrackerDataSetter: any) => {
    var getCreatedBy = `/v1/quotetracker?createdBy=${GetPrincipalId(accessToken)}`; 
    quoteTimelineService.sendCall(getCreatedBy, quoteTimelineService.generateCallOptions(accessToken, "", "GET"), myTrackerDataSetter);
  }

  const myTrackerListOfQuotesSetter = (param: any) => {
    setMyTrackerList(param);
  }

  useEffect(() => {
    if (myTrackerData?.id === '' && accessToken != null)
    {
        FetchMyTracker(quoteTimelineService, accessToken, myTrackerGetCreatedBySetter);
    }
  }, [accessToken]);

  useEffect(() => {
    if (myTrackerData?.id === '' || accessToken == null)
    {
        return;
    }

    var getListOfQuotes = `/v1/quotetracker/quotes/${myTrackerData?.id}`; 
    quoteTimelineService.sendCall(getListOfQuotes, quoteTimelineService.generateCallOptions(accessToken, "", "GET"), myTrackerListOfQuotesSetter);
  }, [myTrackerData.id, accessToken, trackedQuotesRefresh]);

  useEffect(() => {
    let url = location.pathname;
    // update the url based on the selected menu    
    switch (url) {
      case "/my-tracked-proposals":
        setSelectedMenu("listselectsetvalue");
        break;
      case "/business-tracked-proposals":
        setSelectedMenu("favoritelist");
        break;
      case "/":
        setSelectedMenu("search");
        break;
      default:
        if (url?.split('/')?.length === 4)
          setSelectedMenu(`${url.split('/')[2]},empty`);
        break;
    }
    // This code runs after the component has mounted
    setIsFirstLoad(false);
  }, []);

  const [searchParams] = useSearchParams();

  const navigateKeepingParams = (newRoute: any) => {
      navigate(newRoute + '?' + searchParams.toString(), {
        state: {'searchParams': searchParams.toString()}
      });
  };

  useEffect(() => {
    // update the url based on the selected menu    
    
    if (accessToken != null) {
      switch (selectedMenu) {
        case "listselectsetvalue":
          navigate("/my-tracked-proposals");
          break;
        case "favoritelist":
          navigate("/business-tracked-proposals");
          break;
        case "search":
          navigateKeepingParams("/");
          break;
        default:
          if (selectedMenu?.includes(','))
            navigate(`/proposal-details/${selectedMenu.split(',')[0]}`);
          break;
      }
    }
  }, [selectedMenu, accessToken]);



  useEffect(() => {
    // This code will run whenever the URL changes
    const pathSplit = location.pathname.split('/');
    const firstPart = pathSplit[1];
    
    if (firstPart === "proposal-details" && loadedProposalName != null) {
      
      const idName = `${pathSplit[2]},${loadedProposalName}`;

      // Retrieve the array from local storage
      const storedArray = localStorage.getItem('lastViewedProposals');
      let lastViewedProposals = storedArray ? JSON.parse(storedArray) : [];

      // Add the new ID to the array
      if (lastViewedProposals?.includes(idName))
      {
        lastViewedProposals?.splice(lastViewedProposals?.indexOf(idName), 1)
      }

      lastViewedProposals.push(idName);
      
      if (lastViewedProposals.length > 5) {
        lastViewedProposals?.shift();
      }
  
      // Save the array back to local storage
      localStorage.setItem('lastViewedProposals', JSON.stringify(lastViewedProposals));
      setLatestIds(lastViewedProposals);

      setSelectedMenu("");
    }

    return (() => {
      setLoadedProposalName(null);
    })
  }, [location, loadedProposalName]);

  const css = `
    :host {
      --layout-main-padding: 0px !important;
    }
  `;

  const getNameFromToken = (token: any) => {
    if (token) 
    {
      var decodedToken = jwt_decode(token);
      return (decodedToken as any).name;
    }
    return null;
  }

  useEffect(() => {
    if (layoutRef.current) {
      const shadowRoot = (layoutRef.current as any).shadowRoot;
      const style = document.createElement('style');
      style.textContent = css;
      shadowRoot.appendChild(style);
    }

    const storedArray = localStorage.getItem('lastViewedProposals');
    let proposalIds = storedArray ? JSON.parse(storedArray) : [];
    setLatestIds(proposalIds);
  }, []);

  useEffect(() => {
    if (currentSuccessMessage?.length > 0)
      setSuccessMessageClassName("custom-message-bar visible");
  }, [currentSuccessMessage])

  useEffect(() => {
    if (currentErrorMessage?.length > 0)
      setErrorMessageClassName("custom-message-bar visible");
  }, [currentErrorMessage])

  useEffect(() => {
    if (currentWarningMessage?.length > 0)
      setWarningMessageClassName("custom-message-bar visible");
  }, [currentWarningMessage])

  let latestId = latestIds[latestIds.length - 1] ?? `${myTrackerList[0]?.quoteId},${myTrackerList[0]?.quoteName}` ?? "123456789,Test Qyote";
  const userName = getNameFromToken(accessToken);
  return (
    <PageLayoutContext.Provider value={{
      setCurrentErrorMessageWithTimeout: setCurrentErrorMessageWithTimeout,
      setCurrentSuccessMessageWithTimeout : setCurrentSuccessMessageWithTimeout,
      setCurrentlyFetchingData: setCurrentlyFetchingData,
      setCurrentWarningMessageWithTimeout: setCurrentWarningMessageWithTimeout,
      accessToken: accessToken,
      generateToken: S2SToken,
      myTrackerData: myTrackerData,
      myTrackerList: myTrackerList,
      triggerTrackedQuotesRefresh: triggerTrackedQuotesRefresh,
      myTrackerFetching: currentlyFetchingData,
      AddNewQuoteToTrack: AddNewQuoteToTrack,
      RemoveQuoteFromTrackerList: RemoveQuoteFromTrackerList,
      setLoadedProposalName: setLoadedProposalName
    } as any}>
    <Layout ref={layoutRef}>
      <NavHeader
        slot="header"
        style={{'--background-color': 'var(--bs-blue)'}}
        nav-title="Proposal Tracker"
        disableSearch={true}>
          
        <div slot="start" style={{display: 'flex', alignItems: 'center', marginLeft: 10}}>
            <FontIcon  style={{marginRight: 10}} iconName="Bullseye" />
            <text style={{fontWeight: 500}}>Proposal Tracker</text>
        </div>
        {
          isAuthenticated ?
          (<Dropdown slot="action" fixed-placement>
          <Button slot="trigger" appearance="stealth">
            <Persona
              label="Michelle's profile image"
              initials={userName?.split(' ')?.length >= 2 ? `${userName?.split(' ')[0][0]}${userName?.split(' ')[1][0]}` : '?'}
              status-indicator="online"
              style={{ '--size': '28px', '--background-color': 'white', '--text-color': 'blue' }}
            />
  
          </Button>
            <Menu style={{'--background-color': 'var(--bs-white)'}}>
              <MenuItem onClick={() => handleLogout(aadInstance)}>Sign out</MenuItem>
            </Menu>
          </Dropdown>) :
          (
            <Button style={{'--button-background-color': 'var(--bs-gray)', marginRight: 10}} slot="action" appearance="primary" onClick={() => handleLogin(aadInstance)}>Sign in</Button>
          )
        }
        
      </NavHeader>
      {isAuthenticated ? <TaskMenu 
        onHeSelectedChange={(e) => {setSelectedMenu((e as any).target.childNodes[0].id);}}
        style={{'--min-width': menuIsExpanded ? '280px' : '10px', display: menuIsExpanded ? '' : 'none' }}
        item-type="page"
        expanded={menuIsExpanded}
        autoCollapse={false}
        slot="menu"
      >
      <div style={{}}>
        <Button appearance="stealth" id="button5" onClick={(e) => setMenuIsExpanded(!menuIsExpanded)}><Icon slot="start" name={menuIsExpanded ? "openpane" : "openpanemirrored"} /></Button>
        <Tooltip anchor="button5" placement="end">
          <span>{menuIsExpanded ? "Collapse menu" : "Expand menu"}</span>
        </Tooltip>
      </div>
          <TaskItem selected={selectedMenu === "listselectsetvalue"}>
            <Icon slot="start" id="listselectsetvalue" name="listselectsetvalue" />
            <text style={{display: menuIsExpanded ? '' : 'none'}}>My tracked proposals</text>
          </TaskItem>
          <TaskItem selected={selectedMenu === "favoritelist"}>
            <Icon slot="start" id="favoritelist" name="favoritelist" />
            <text style={{display: menuIsExpanded ? '' : 'none'}}>Business-tracked proposals</text>
          </TaskItem>
          <TaskItem selected={selectedMenu === "search"}>
            <Icon slot="start" id="search" name="search" />
            <text style={{display: menuIsExpanded ? '' : 'none'}}>View all proposals</text>
          </TaskItem>
          {
            latestIds?.length > 0 ? <div>
              <TaskItem style={{display: menuIsExpanded ? '' : 'none', marginTop: '35px'}}>
              Last viewed
              {
                [...latestIds].reverse().map((id: any) => {
                  return (
                    <TaskItem selected={location.pathname.includes(id.split(',')[0])} key={id}>
                      <img id={id} slot="start" src={process.env.PUBLIC_URL + '/f067.svg'} style={{ width: '18px', height: '18px' }} alt="document with a clock icon" />
                      <div style={{ maxWidth: '250px', wordWrap: 'break-word' }}>
                        <text>{id.split(',')[1]}</text>
                    </div>
                    </TaskItem>
                  );
                })
              }
              </TaskItem>
            </div> : null
          }
      </TaskMenu> : null}

      {isAuthenticated ? <TaskMenu onHeSelectedChange={(e) => {setSelectedMenu((e as any).target.childNodes[0].id)}} item-type="page" slot="menu" style={{display: menuIsExpanded ? 'none' : '', '--min-width': '10px' }}>
          <div style={{}}>
            <Button appearance="stealth" id="button5" onClick={(e) => setMenuIsExpanded(!menuIsExpanded)}><Icon slot="start" name="openpanemirrored" /></Button>
            <Tooltip anchor="button5" placement="end">
              <span>{menuIsExpanded ? "Collapse menu" : "Expand menu"}</span>
            </Tooltip>
          </div>
          <TaskItem selected={selectedMenu === "listselectsetvalue"}>
            <Icon slot="start" name="listselectsetvalue" id="listselectsetvalue" />
          </TaskItem>
          <TaskItem selected={selectedMenu === "favoritelist"}>
            <Icon slot="start" name="favoritelist" id="favoritelist" />
          </TaskItem>
          <TaskItem selected={selectedMenu === "search"}>
            <Icon slot="start" name="search" id="search" />
          </TaskItem>
          <TaskItem selected={location.pathname.includes('proposal-details')} key={location.pathname.includes('proposal-details') ? `${id},${loadedProposalName}` : latestId}>
            <img id={location.pathname.includes('proposal-details') ? `${id},${loadedProposalName}` : latestId} slot="start" src={process.env.PUBLIC_URL + '/f067.svg'} style={{ width: '18px', height: '18px' }} alt="document with a clock icon" />
          </TaskItem>
        </TaskMenu> : null}
      <div style={{backgroundColor: "#fcfcfc", padding: '10px 40px 5vw 40px'}}>
          <div style={{display: errorMessageClassName.includes('visible') || successMessageClassName.includes('visible') || warningMessageClassName.includes('visible') ? '' : 'none', marginBottom: '40px', transition: 'opacity 500ms, height 600ms, min-height 600ms, margin-top 620ms, margin-left 650ms ease-in-out'}}>
            <MessageBar
            id="errorMessageBar"
            className={errorMessageClassName}
            messageBarType={MessageBarType.error}
            onDismiss={clearErrorMessage}
            >{currentErrorMessage}</MessageBar>
            <MessageBar
            id="successMessageBar"
            className={successMessageClassName}
            messageBarType={MessageBarType.success}
            onDismiss={clearSuccessMessage}
            >{currentSuccessMessage}</MessageBar>
            <MessageBar
            id="warningMessageBar"
            className={warningMessageClassName}
            messageBarType={MessageBarType.warning}
            onDismiss={clearWarningMessage}
            >{currentWarningMessage}</MessageBar>
          </div>
          <AuthenticatedTemplate>
            {props.children}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <AccessPackages />
          </UnauthenticatedTemplate>
      </div>
      <Navbar
        fixed="bottom"
        variant="dark"
        style={{
          backgroundColor: "#f0f2f5",
          height: "4vh",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <a
          style={{
            color: "#616161",
            marginLeft: 20
          }}>© Microsoft {new Date().getFullYear()}</a>
        <a
          style={{
            color: "#616161"
          }}
          target="_blank" 
          href="http://go.microsoft.com/fwlink/?LinkId=521839">Privacy & Cookies</a>
         <a
          style={{
            color: "#616161",
          }} 
          target="_blank"
          href="https://go.microsoft.com/fwlink/?LinkID=206977">Terms of use</a>
        <a
          style={{
            color: "#616161",
          }} 
          target="_blank"
          href="mailto:pthelp@microsoft.com">Contact Us</a>

        <img src="/msfooterlogo.png" alt="Microsoft Company Logo" height="90%" style={{marginRight: 20}} />
      </Navbar>
      <style>{css}</style>
    </Layout>
    </PageLayoutContext.Provider>
  );
};
