import { icon, createScope, dataGrid, button, divider, partnerCenterTheme } from '@harmony/enablers/react';
import React from 'react';

var scope = createScope({
    styles: [partnerCenterTheme],
    reactInstance: React,
    components: [dataGrid, button, divider]
})

const DataGrid = scope.forReact(dataGrid);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);

var quoteColumns = [
    {
        field: `email`,
        content: `Email`,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '500px'
        }
    },
    {
        field: `type`,
        content: `Role`,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '500px'
        }
    }
];

var approvalColumns = [
    {
        field: `email`,
        content: ``,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '500px'
        }
    }
];

var agreementColumns = [
    {
        field: `fullName`,
        content: `Full Name`,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '500px'
        }
    },
    {
        field: `email`,
        content: `Email`,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '500px'
        }
    },
    {
        field: `type`,
        content: `Role`,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '500px'
        }
    }
]

export const ParticipantsView = (props: any) => {
    var quoteRows = GenerateQuoteRows(props.quoteData);
    var approvalRows = GenerateApprovalRows(props.quoteData);
    var agreementRows = GenerateAgreementRows(props.quoteData);
    return (
        <div>
            {
                quoteRows?.length > 0 ?
                <div>
                <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                    <img src={process.env.PUBLIC_URL + '/infodoc.svg'} style={{ width: '16px', height: '16px', marginRight: 8 }} alt="document with a clock icon" />
                    <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Proposal participants</span>
                </div>
                
                <DataGrid
                    columns={quoteColumns}
                    rows={quoteRows as any}
                    label="Quote Participants"
                />
                </div>
                : null
            }
            {
                approvalRows?.length > 0 ?
                <>
                <div>
                <div style={{ marginTop: 25, display: 'flex', alignItems: 'center' }}>
                    <Icon name="accept" style={{marginRight: 8}} />
                    <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Approval participants</span>
                </div>
                <DataGrid
                    columns={approvalColumns}
                    rows={approvalRows as any}
                    label="Approval Participants"
                >
                    {
                        approvalRows?.map(row => (
                            row?.parentId != null && (row?.cells?.email?.endsWith("microsoft.com")) ?
                            <span slot={`email-${row.id}`} key={`email-${row.id}`}>
                                <Button appearance='link' onclick={() => {
                                    window.open(
                                        `https://whoplus.microsoft.com/Org/${row.cells.email.split('@')[0]}?_vwp=true&_vwpAlias=${row.cells.email.split('@')[0]}`,
                                        "asd",
                                        "width=500,height=500")
                                }}>{row.cells.email}</Button>
                            </span> : null
                        ))
                    }
                </DataGrid>
                </div>
                </>
                : null
            }
            {
                agreementRows?.length > 0 ?
                <>
                <div>
                <div style={{ marginTop: 25, display: 'flex', alignItems: 'center' }}>
                    <img src={process.env.PUBLIC_URL + '/f029.svg'} style={{ width: '16px', height: '16px', marginRight: 8 }} alt="document with a clock icon" />
                    <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Agreement participants</span>
                </div>
                <DataGrid
                    columns={agreementColumns}
                    rows={agreementRows as any}
                    label="Agreement Participants"
                />
                </div>
                </>
                : null
            }
        </div>
    );
  };

  const GenerateQuoteRows = (data: any) => {
    return data?.quote?.players?.map((el: any) => {
        const row = {
            id: el?.id,
            cells: {email: el?.email ?? '<Unknown>', type: el?.type}
        }
        return row;
    })
  }
  

  function onlyUnique(value: any, index: any, array: any) {
    return array.indexOf(value) === index && value != null;
  }

  const GenerateApprovalRows = (data: any) => {
    var rows: any[] = [];
    data?.approvals?.forEach((approval: any) => {
        rows = rows.concat(CollectApprovalParticipants(approval) as any[] ?? [])
        // console.log(rows)
    })
    return rows;
  }

  const CollectApprovalParticipants = (approval: any) => {
    if (approval?.players?.length == 0)
    {
        return null;
    }

    var titleRow = {
        id: approval?.type + "title",
        expanded: true,
        cells: {email: approval?.type}
    }

    var topLevelRows = approval?.players?.map((el: any) => el?.type)
    topLevelRows = topLevelRows?.filter(onlyUnique);
    topLevelRows = topLevelRows?.filter((el: any) => el != "Seller").map((el: any) => {
        var row = {
            id: el as string + approval?.type,
            parentId: approval?.type + "title",
            expanded: true,
            cells: {email: "Policy: " + el as string}
        }
        return row
    });
    var midLevelRows = approval?.players?.map((el: any) => el?.level == null ? null : el?.level + '%' + el?.type)
    midLevelRows = midLevelRows?.filter(onlyUnique);
    // console.log(midLevelRows)
    midLevelRows = midLevelRows?.map((el: any) => {
        var firstEl = el.split('%')[0]
        var secondEl = el.split('%')[1] 
        // console.log(el)
        if (el != null)
        {
            var row = {
                id: firstEl == "NCOE" ? "NCOEid" : firstEl as string + approval?.players?.find((player: any) => player?.level === firstEl && player?.type == secondEl).type + approval?.type,
                parentId: approval?.players?.find((player: any) => player?.level === firstEl && player?.type == secondEl).type + approval?.type,
                expanded: true,
                cells: {email: "Level: " + firstEl as string}
            }
            return row;
        }
    });

    var subLevelRows = approval?.players?.filter((el: any) => el?.type != "Seller").map((el: any, idx: number) => {
        const row = {
            id: el?.id + idx + el?.level + el?.type + approval?.type,
            parentId: el?.email == "NCOE" ? "NCOEid" : el?.level + el?.type + approval?.type,
            cells: {email: el?.email ?? '<Unknown>'}
        }
        return row;
    })
    if (topLevelRows?.length > 0)
    {
        return [titleRow, ...topLevelRows, ...midLevelRows, ...subLevelRows];
    }
  }

  const GenerateAgreementRows = (data: any) => {
    var topLevelRows = data?.agreement?.players?.map((el: any) => el?.type)
    topLevelRows = topLevelRows?.filter(onlyUnique);
    topLevelRows = topLevelRows?.map((el: any) => {
        var row = {
            id: el as string,
            expanded: true,
            cells: {fullName: el as string}
        }
        return row
    });

    var subLevelRows = data?.agreement?.players?.map((el: any, idx: number) => {
        const row = {
            id: el?.id + idx,
            parentId: el?.level ?? el?.type,
            cells: {email: el?.email ?? '<Unknown>', fullName: `${el?.firstName ?? "<Unknown>"} ${el?.lastName ?? ""}`, type: el?.role}
        }
        return row;
    })

    if (topLevelRows?.length > 0)
    {
        return [...topLevelRows, ...subLevelRows];
    }
    return [];
  }